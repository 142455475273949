import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { Spin, Button, Dropdown } from 'antd';
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './UsersPost.css';
import Masonry from 'react-masonry-css';

function UserPosts({ userId }) {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.auth);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get('/users/profile/me/');
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const response = await axiosInstance.get(`/users/profile/${userId}/posts/`);
        console.log('Fetching posts for user', response.data);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserPosts();
    }
  }, [userId]);

  const handlePostClick = (postId) => {
    navigate(`/posts/${postId}`);
  };

  const handleDeletePost = async (postId) => {
    try {
      await axiosInstance.delete(`/posts/${postId}/`);
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const breakpointColumns = {
    default: 2,
    768: 1
  };

  if (loading) {
    return <div className="loading-container"><Spin size="large" /></div>;
  }

  return (
    <div className="posts-container">
      <div className="posts-header">
        {isAuthenticated && currentUser && currentUser.id === parseInt(userId) && (
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => navigate('/create-post')}
            className="users-posts-create-post-button"
          >
            Create Post
          </Button>
        )}
      </div>
      
      {posts.length === 0 ? (
        <div className="no-posts-container">
          <p className="no-posts-message">No posts yet</p>
        </div>
      ) : (
        <Masonry
          breakpointCols={breakpointColumns}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {posts.map(post => (
            <div 
              key={post.id} 
              className="post"
              onClick={() => handlePostClick(post.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="post-header">
                <span className="post-author">{post.user}</span>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: 'Edit',
                        onClick: (e) => {
                          if (e) e.domEvent.stopPropagation();
                          navigate(`/edit-post/${post.id}`);
                        }
                      },
                      {
                        key: '2',
                        label: 'Delete',
                        onClick: (e) => {
                          if (e) e.domEvent.stopPropagation();
                          handleDeletePost(post.id);
                        }
                      }
                    ]
                  }}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Button
                    icon={<MoreOutlined />}
                    className="post-menu-button"
                    onClick={(e) => e.stopPropagation()}
                  />
                </Dropdown>
              </div>
              {post.posts_images && post.posts_images.length > 0 && (
                <div className="post-images">
                  <img 
                    src={post.posts_images[0].image} 
                    alt={`Post by ${post.user}`}
                    onError={(e) => console.error('Image failed to load:', e)}
                  />
                </div>
              )}
              <div className="post-content">
                <div className="post-caption">{post.caption}</div>
                <div className="post-footer">
                  <span className="post-date">
                    {new Date(post.created_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
}

export default UserPosts;