import React from 'react';
import './MoreDropdown.css';
import { logout } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance'; // Import axiosInstance

function MoreDropdown({ onNavigate, isAuthenticated }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await axiosInstance.post('/users/logout/', {
        refresh: refreshToken
      });

      if (response.status === 200) {
        dispatch(logout());
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        // Verify tokens are removed
        console.log('Tokens after removal:', {
          token: localStorage.getItem('token'),
          refreshToken: localStorage.getItem('refreshToken')
        });

        // Try to make an authenticated request to verify logout
        try {
          const testResponse = await axiosInstance.get('/users/profile/me');
          console.log('Should not succeed:', testResponse);
        } catch (error) {
          console.log('Successfully logged out - authenticated request failed as expected:', error.response.status);
        }
        
        navigate('/');
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      // If the API call fails, still logout locally
      dispatch(logout());
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/');
    }
  };

  return (
    <div className="more-dropdown">
      <button className="dropdown-item" onClick={() => onNavigate('/settings')}>Settings</button>
      <button className="dropdown-item" onClick={() => onNavigate('/help')}>Help</button>
      <button className="dropdown-item" onClick={() => onNavigate('/feedback')}>Give Feedback</button>
      {isAuthenticated && (
        <button className="dropdown-item" onClick={handleLogout}>Log Out</button>
      )}
    </div>
  );
}

export default MoreDropdown;