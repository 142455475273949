import { getTopLabel } from './labelHelpers';

class MarkerManager {
  constructor(map, AMapAPI) {
    console.log('MarkerManager constructor called with:', {
      hasMap: !!map,
      hasAMapAPI: !!AMapAPI
    });
    this.map = map;
    this.AMapAPI = AMapAPI;
    this.markers = new Map();
    this.activeMarker = null;
    this.searchResultMarkers = new Set();
    
    this.markerStyles = {
      default: {
        scale: 1,
        zIndex: 100,
        backgroundColor: '#df2c2c',
        textColor: '#ffffff',
        borderColor: '#df2c2c',
        defaultIcon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
      },
      active: {
        scale: 1.2,
        zIndex: 200,
        backgroundColor: '#df2c2c',
        textColor: '#ffffff',
        borderColor: '#df2c2c',
        defaultIcon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
      },
      search: {
        scale: 0.9,
        zIndex: 50,
        backgroundColor: '#df2c2c',
        textColor: '#ffffff',
        borderColor: '#df2c2c',
        defaultIcon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png'
      }
    };
  }

  // Create custom marker content
  createCustomMarkerContent(poi, style) {
    const topLabel = getTopLabel(poi.labels);
    const div = document.createElement('div');
    div.className = 'custom-marker';
    
    div.innerHTML = `
      <div class="marker-container" style="
      background-color: ${this.markerStyles[style].backgroundColor};
      color: ${this.markerStyles[style].textColor};
      border: 2px solid ${this.markerStyles[style].borderColor};
      padding: 2px 6px;
      border-radius: 16px;
      font-size: 12px;
      // font-weight: bold;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      transform: scale(${this.markerStyles[style].scale});
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 15px;
      ">
      ${topLabel ? topLabel.label.name : poi.name}
      </div>
      `;
      
    return div;
  }

  // Create marker
  createMarker(poi, style = 'default') {
    const topLabel = getTopLabel(poi.labels);
    const markerConfig = {
      position: [poi.location.lng, poi.location.lat],
      title: poi.name,
      zIndex: this.markerStyles[style].zIndex,
      extData: poi
    };

    if (topLabel) {
      // Use custom HTML content if there's a label
      markerConfig.content = this.createCustomMarkerContent(poi, style);
      // markerConfig.offset = new this.AMapAPI.Pixel(-30, -15);
    } else {
      // Use default map marker if no labels
      markerConfig.icon = new this.AMapAPI.Icon({
        size: new this.AMapAPI.Size(25, 34),
        image: this.markerStyles[style].defaultIcon,
        imageSize: new this.AMapAPI.Size(25, 34)
      });
      // markerConfig.offset = new this.AMapAPI.Pixel(-12, -34);
    }

    const marker = new this.AMapAPI.Marker(markerConfig);
    this.markers.set(poi.id, marker);
    this.map.add(marker);
    return marker;
  }

  setActiveMarker(poi) {
    // Clear previous active marker
    if (this.activeMarker) {
      this.map.remove(this.activeMarker);
      this.markers.delete(this.activeMarker.getExtData().id);
    }

    if (poi) {
      const marker = this.createMarker(poi, 'active');
      this.activeMarker = marker;
      this.map.setCenter([poi.location.lng, poi.location.lat - 0.001]);
    } else {
      this.activeMarker = null;
    }
  }

  addSearchResultMarkers(pois, onMarkerClick) {
    // Clear previous search results
    this.clearSearchResults();
    
    pois.forEach(poi => {
      if (!this.markers.has(poi.id)) {
        const marker = this.createMarker(poi, 'search');
        marker.on('click', () => onMarkerClick(poi));
        this.searchResultMarkers.add(marker);
      }
    });

    if (pois.length > 0) {
      this.map.setFitView([...this.searchResultMarkers]);
    }
  }

  clearSearchResults() {
    this.searchResultMarkers.forEach(marker => {
      if (marker !== this.activeMarker) {
        this.map.remove(marker);
        this.markers.delete(marker.getExtData().id);
      }
    });
    this.searchResultMarkers.clear();
  }

  clearAll() {
    this.markers.forEach(marker => this.map.remove(marker));
    this.markers.clear();
    this.searchResultMarkers.clear();
    this.activeMarker = null;
  }
}

export default MarkerManager;