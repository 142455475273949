import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from './store/authSlice';
import { setIsMobile } from './store/uiSlice';
import Navbar from './components/Navbar/Navbar';
import MNavBar from './components/MNavBar/MNavBar';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import MapContainer from './components/MapContainer/MapContainer';
import Help from './pages/Help/Help';
import Feedback from './pages/Feedback/Feedback';
import MobileSettings from './pages/Settings/MobileSettings';
import Settings from './pages/Settings/Settings';
import Chat from './pages/Chat/Chat';
import Discover from './pages/Discover/Discover';
import AuthModal from './components/AuthModal/AuthModal';
import Notifications from './pages/Notifications/Notifications';
import './App.css';
import axiosInstance from './api/axiosInstance';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FollowersFollowingList from './components/FollowersFollowingList/FollowersFollowingList';
import Tours from './pages/Tours/Tours';
import CategoryTours from './pages/Tours/CategoryTours';
import IndividualTours from './pages/Tours/IndividualTours';
import CreatePost from './pages/Posts/CreatePost';
import PostsGrid from './pages/Discover/PostsGrid';
import PostDetail from './pages/Posts/PostDetail';
import EditPost from './pages/Posts/EditPost';
// store
import { setIsAuthModalOpen } from './store/modalSlice';

function App() {
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.ui.isMobile);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isAuthModalOpen = useSelector(state => state.modal.isAuthModalOpen);

  const verifyToken = useCallback(async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      dispatch(setAuth({ isAuthenticated: false, user: null }));
      dispatch(setIsAuthModalOpen(true));
      return;
    }
  
    try {
      const verifyResponse = await axiosInstance.post('users/auth/verify/', 
        { token },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (verifyResponse.status === 200) {
        try {
          // If no user data in localStorage, fetch it from backend
          const userResponse = await axiosInstance.get('users/profile/me/', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const userData = userResponse.data;
          localStorage.setItem('userData', JSON.stringify(userData));
          dispatch(setAuth({ 
            isAuthenticated: true, 
            user: userData
          }));
          setIsAuthModalOpen(false);
          return;
        } catch (error) {
          throw new Error('Failed to fetch user data');
        } 
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userData'); // Also remove user data
        dispatch(setAuth({ isAuthenticated: false, user: null }));
        setIsAuthModalOpen(true);
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      dispatch(setAuth({ isAuthenticated: false, user: null }));
      setIsAuthModalOpen(true);
    }
  }, [dispatch]);

  const handleResize = useCallback(() => {
    dispatch(setIsMobile(window.innerWidth < 768));
  }, [dispatch]);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setIsAuthModalOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  useEffect(() => {
    verifyToken();
    window.addEventListener('resize', handleResize);
    handleResize(); // call it once to set the initial isMobile state
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, isAuthenticated, verifyToken, handleResize]);

  // re-evaluate isMobile when auth state changes
  useEffect(() => {
    handleResize();
  }, [isAuthenticated, handleResize]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onScriptLoadError={() => console.error('Failed to load Google script')}
    >
      <Router>
        <div className="App">
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/:userId" element={<Profile />} />
              <Route path="/map" element={<MapContainer />} />
              <Route path="/help" element={<Help />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/tours" element={<Tours />} />
              <Route path="/category/:id" element={<CategoryTours />} />
              <Route path="/location/:id" element={<CategoryTours />} />
              <Route path="/tour/:id" element={<IndividualTours />} />

              <Route path="/posts" element={<PostsGrid />} />
              <Route path="/create-post" element={<CreatePost />} />
              <Route path="/posts/:postId" element={<PostDetail />} />
              <Route path="/edit-post/:postId" element={<EditPost />} />

              {/* Settings routes */}
              <Route path="/settings" element={isMobile ? <MobileSettings /> : <Settings />}>
                <Route index element={<Navigate to="/settings/edit_profile" replace />} />
                <Route path=":section" element={isMobile ? <MobileSettings /> : <Settings />} />
              </Route>

              {/* <Route 
                path="/notifications" 
                element={
                  isAuthenticated ? <Notifications /> : <Navigate to="/" replace />
                } 
              /> */}
              <Route path="/profile/:userId">
                <Route path="followers" element={<FollowersFollowingList type="followers" />} />
                <Route path="following" element={<FollowersFollowingList type="following" />} />
              </Route>
              <Route path="/chat" element={<Chat />} />
              <Route path="/discover" element={<Discover />} />
            </Routes>
          </div>
          <AuthModal visible={isAuthModalOpen} onClose={() => dispatch(setIsAuthModalOpen(false))} />
          {!isMobile && <Navbar setIsAuthModalOpen={setIsAuthModalOpen}/>}
          {isMobile && <MNavBar setIsAuthModalOpen={setIsAuthModalOpen}/>}
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;