// Page 3: Individual Tours

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { useParams } from 'react-router-dom';
import './IndividualTours.css'; // Create a CSS file for styling

const IndividualTour = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const response = await axiosInstance.get(`/tours/tours/${id}/`);
        setTour(response.data);
      } catch (error) {
        console.error('Error fetching tour details:', error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get(`/tours/reviews/?tour=${id}`);
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchTourDetails();
    fetchReviews();
  }, [id]);

  if (!tour) return <p>Loading...</p>;

  return (
    <div className="individual-tour-container">
      <h1>{tour.title}</h1>
      <img src={tour.image_url} alt={tour.title} className="tour-image" />
      <p>{tour.description}</p>
      <p>Price: ${tour.price}</p>
      <h2>Reviews</h2>
      <div className="reviews-list">
        {reviews.length > 0 ? (
          reviews.map(review => (
            <div key={review.id} className="review-card">
              <p><strong>{review.user.username}</strong>: {review.comment}</p>
              <p>Rating: {review.rating}</p>
            </div>
          ))
        ) : (
          <p>No reviews available.</p>
        )}
      </div>
      <button className="book-button">Book Now</button>
    </div>
  );
};

export default IndividualTour;