import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    // Resource file (JSON format)
    resources: {
      en: {
        translation: {
          "Welcome": "Welcome",
          "Settings": "Settings",
          "Language": "Language",
          "English": "English",
          "Chinese": "Chinese",
          "Map Language": "Map Language",
          "Chinese and English": "Chinese and English",
          // Navbar translations
          "Home": "Home",
          "Discover": "Discover",
          "Search placeholder": "Search by name, location, or label...",
          "Search": "Search",
          "Chat": "Chat",
          "Profile": "Profile",
          "Log In": "Log In"
        }
      },
      zh: {
        translation: {
          "Welcome": "欢迎",
          "Settings": "设置",
          "Language": "语言",
          "English": "英语",
          "Chinese": "中文",
          "Map Language": "地图语言",
          "Chinese and English": "中英双语",
          // Navbar translations
          "Home": "首页",
          "Discover": "发现",
          "Search placeholder": "按名称、位置或标签搜索...",
          "Search": "搜索",
          "Chat": "聊天",
          "Profile": "个人资料",
          "Log In": "登录"
        }
      }
    },
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language if no translation is found
    interpolation: {
      escapeValue: false // React already escapes values to prevent XSS
    }
  });

export default i18n;