// axios: to make requests to the Django API endpoints
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, //https://localhost:8000/api/v1
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(function (config) {
  // Extract CSRF token from cookies (include in the request headers)
  const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken.split('=')[1];
  }

  // Add Authorization token if available (include in the request headers)
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default axiosInstance;

