import React, { useState, useRef, useEffect } from 'react';
import { MessageList, Input } from 'react-chat-elements';
import { useSelector } from 'react-redux';
import axiosInstance from '../../api/axiosInstance';
import 'react-chat-elements/dist/main.css';
import './Chat.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Chat() {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    
    // Load messages when component mounts
    useEffect(() => {
        if (isAuthenticated) {
            // fetchMessages();
            console.log('isAuthenticated');
        } else {
            const sessionMessages = sessionStorage.getItem('chatMessages');
            if (sessionMessages) {
                setMessages(JSON.parse(sessionMessages));
            }
        }
    }, [isAuthenticated]);

    // Save messages to sessionStorage when they change
    useEffect(() => {
        if (!isAuthenticated && messages.length > 0) {
            sessionStorage.setItem('chatMessages', JSON.stringify(messages));
        }
    }, [messages, isAuthenticated]);

    // const fetchMessages = async () => {
    //     try {
    //         const response = await axiosInstance.get('/chat/messages/');
    //         setMessages(response.data);
    //     } catch (error) {
    //         console.error('Error fetching messages:', error);
    //     }
    // };

    const sendMessage = async () => {
        if (!message.trim()) return;

        const newUserMessage = {
            text: message,
            type: 'text',
            position: 'right',
        };

        // Add user message to UI
        addMessage(newUserMessage);
        setMessage('');

        // Add initial bot message
        const botMessageId = Date.now();
        const botMessage = {
            id: botMessageId,
            type: 'text',
            position: 'left',
            text: '',
        };
        addMessage(botMessage);

        try {
            // const apiEndpoint = process.env.NODE_ENV === 'production' 
            //     ? 'https://www.chinago123.com/query'
            //     : `http://localhost:${process.env.FLASK_PORT || 8080}/query`;

            // const response = await fetch(apiEndpoint, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: message }),
            // });
            
            // const data = await response.json();
            // const botResponse = data.response;

            // setMessages(prev => {
            //     const newMessages = prev.map(msg => 
            //         msg.id === botMessageId 
            //             ? { ...msg, text: botResponse }
            //             : msg
            //     );
            //     return newMessages;
            // });

            const apiEndpoint = process.env.NODE_ENV === 'production' 
                ? 'https://www.chinago123.com/query/stream'
                : `http://localhost:${process.env.FLASK_PORT || 8080}/query/stream`;

            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: message }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let fullText = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n');
                
                for (const line of lines) {
                    if (line.startsWith('data: ') && line.trim() !== 'data: ') {
                        try {
                            const data = JSON.parse(line.slice(6));
                            if (data.chunk) {
                                fullText += data.chunk;
                                setMessages(prev => {
                                    const newMessages = prev.map(msg => 
                                        msg.id === botMessageId 
                                            ? { ...msg, text: fullText }
                                            : msg
                                    );
                                    return newMessages;
                                });
                            }
                            if (data.error) {
                                throw new Error(data.error);
                            }
                        } catch (e) {
                            console.error('Error parsing SSE data:', e);
                            throw e;
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);
            handleError(botMessageId);
        }
    };

    const handleError = (loadingId) => {
        setMessages(prev => {
            const filtered = prev.filter(msg => msg.id !== loadingId);
            return [...filtered, {
                type: 'text',
                position: 'left',
                text: 'Error: Could not get response',
            }];
        });
    };

    const addMessage = (messageObj) => {
        setMessages(prev => [...prev, messageObj]);
    };

    useEffect(() => {
        const handleResize = () => {
            // Check if mobile device
            if (window.innerWidth <= 768) {
                // Scroll to bottom when keyboard opens
                window.scrollTo(0, document.body.scrollHeight);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="modern-chat-container">
            <div className="modern-chat-header">
                <h1>GoAssistant</h1>
            </div>
            <div className="modern-chat-messages">
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={messages.map(msg => ({
                        ...msg,
                        text: <ReactMarkdown 
                            remarkPlugins={[remarkGfm]} 
                            className="markdown-content"
                            children={msg.text}
                        />
                    }))}
                />
                <div ref={messagesEndRef} />
            </div>
            <div className="modern-chat-input">
                <Input
                    placeholder="Type your message here..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rightButtons={
                        <button className="send-button" onClick={sendMessage}>
                            Send
                        </button>
                    }
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
            </div>
        </div>
    );
}

export default Chat;