import "./MapContainer.css";
import '../../styles/shared/GoTag.css';
import axiosInstance from "../../api/axiosInstance";

// libraries
import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// store
import { setIsAuthModalOpen, setIsMoreDropdownOpen } from '../../store/modalSlice';

// components
import InfoWindow from '../InfoWindow/InfoWindow';
import MobileInfoWindow from '../InfoWindow/MobileInfoWindow';
import SearchBar from '../SearchBar/SearchBar';

// hooks
import { useMapSetup } from './hooks/useMapSetup';
import { useEventListeners } from './hooks/useEventListeners';

// utils
import { handleLanguageChange, toggleLanguageSelector } from './utils/languageHelpers';
import { fetchLabels, addLabel, deleteLabel } from './utils/labelHelpers';
import MarkerManager from './utils/MarkerManager';
import StateManager from './utils/StateManager';
import { fetchPOIDetails } from './utils/poiHelpers';

// main component
export default function MapContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const location = useLocation();

  const language = useSelector(state => state.lang.language);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Define searchQuery state
  const [poiResults, setPoiResults] = useState([]);
  const [activePOI, setActivePOI] = useState(null);
  const isManagersInitialized = useRef(false); // a ref that tracks if the marker and state managers have been initialized

  // map setup hook (handleSetActivePOI depends on this)
  const { map, AMapAPI, placeSearchRef, autoCompleteRef } = useMapSetup(language);
   // Handle publicGoTags dropdown options
  const [publicGoTags, setPublicGoTags] = useState([]);
  const { isAuthenticated, user: currentUser } = useSelector(state => state.auth);

  // state manager
  const markerManagerRef = useRef(null);
  const stateManagerRef = useRef(null);

  // Initialize managers after map setup
  useEffect(() => {
    console.log('Map Setup Debug:', {
      mapExists: !!map,
      AMapAPIExists: !!AMapAPI,
      currentInitStatus: isManagersInitialized.current,
      timestamp: new Date().toISOString()
    });

    if (map && AMapAPI) {
      markerManagerRef.current = new MarkerManager(map, AMapAPI);
      stateManagerRef.current = new StateManager(markerManagerRef.current, setActivePOI);
      isManagersInitialized.current = true;
      console.log('Managers created successfully:', {
        markerManagerExists: !!markerManagerRef.current,
        stateManagerExists: !!stateManagerRef.current,
        newInitStatus: isManagersInitialized.current,
        timestamp: new Date().toISOString()
      });
    }
  }, [map, AMapAPI]);

  // Handle setting active POI
  const handleSetActivePOI = useCallback(async (poi) => {
    if (!isManagersInitialized.current) {
      console.log('isManagersInitialized: ', isManagersInitialized);
      console.log('Waiting for managers to initialize...');
      return;
    }
    
    if (poi) {
      try {
        let fullPoi;
        // If POI is from profile page (missing full details)
        if (poi.poi_id && !poi.location) {
          fullPoi = await fetchPOIDetails(poi.poi_id, placeSearchRef);
        } else {
          fullPoi = poi; // POI already has full details
        }
        
        // Fetch labels
        const labels = fullPoi.labels || await fetchLabels(fullPoi.id);
        
        // Set active POI
        await stateManagerRef.current.setActgivePOI({
          ...fullPoi,
          labels
        });
      } catch (error) {
        console.error('Error setting active POI:', error);
      }
    } else {
      stateManagerRef.current.setActivePOI(null);
    }
  }, []);

  // Handle POI selection from Profile page (GoTagsSection)
  useEffect(() => {
    console.log('isManagersInitialized.current: ', isManagersInitialized.current); // getting false
    console.log('location.state: ', location.state); // works

    console.log('POI Selection Debug:', {
      isManagersInitialized: isManagersInitialized.current,
      hasLocationState: !!location.state,
      selectedPOI: location.state?.selectedPOI,
      timestamp: new Date().toISOString()
    });

    if (isManagersInitialized.current && location.state?.selectedPOI) {
      console.log('Selected POI from location state:', location.state.selectedPOI);
      handleSetActivePOI(location.state.selectedPOI);
    } else {
      console.log('No selected POI in location state');
    }
  }, [location.state, handleSetActivePOI, map, AMapAPI]);

  // other handlers
  const handleClear = useCallback(() => {
    setSearchQuery('');
    setPoiResults([]);
    stateManagerRef.current?.clearAll();
  }, []);

  const handleContainerClick = useCallback((e) => {
    const isHotspot = e.target.closest('.map-hotspot');
    const isMapElement = e.target.closest('#container');
    if (!e.target.closest('.search-overlay') && 
        !e.target.closest('.poi-results') &&
        !e.target.closest('.more-dropdown') &&
        !e.target.closest('.mobile-info-window') &&
        !e.target.closest('.custom-info-window') &&
        !isHotspot && 
        isMapElement) {
      dispatch(setIsMoreDropdownOpen(false));
    }
  }, [dispatch]);

  // Add a label to a POI
  const handleAddLabel = async (labelName, poi, isPublic) => {
    if (!activePOI || !labelName.trim()) return;

    if (!isAuthenticated) {
      dispatch(setIsAuthModalOpen(true));
      return false;
    }

    try {
      const poiData = {
        amap_id: poi.id,
        name: poi.name,
        address: poi.address,
        type: poi.type,
        location: {
          type: 'Point',
          coordinates: [parseFloat(poi.location.lng), parseFloat(poi.location.lat)]
        }
      };
    
      const response = await addLabel(activePOI.id, labelName, poiData, isPublic);
      
      // Immediately update the UI with the new label
      setActivePOI(prev => ({
        ...prev,
        labels: [...prev.labels, {
          label: {
            id: response.id, // Assuming the response contains the new label's ID
            name: labelName,
            is_public: isPublic,
            created_by: currentUser.id
          },
          count: 1
        }]
      }));

      // Then fetch updated labels to ensure consistency
      const updatedLabels = await fetchLabels(activePOI.id);
      setActivePOI(prev => ({
        ...prev,
        labels: updatedLabels
      }));
    } catch (error) {
      console.error('Error adding label:', error);
    }
    return true;
  };

  // Handle label delete
  const handleDeleteLabel = async (labelId) => {
    if (!activePOI) return;
    
    try {
      // Immediately update UI by removing the label
      setActivePOI(prev => ({
        ...prev,
        labels: prev.labels.filter(label => label.label.id !== labelId)
      }));

      const success = await deleteLabel(activePOI.id, labelId);
      if (!success) {
        // If deletion failed, fetch the original labels to restore state
        const updatedLabels = await fetchLabels(activePOI.id);
        setActivePOI(prev => ({
          ...prev,
          labels: updatedLabels
        }));
      }
    } catch (error) {
      console.error('Error deleting label:', error);
      // Restore original labels if there was an error
      const updatedLabels = await fetchLabels(activePOI.id);
      setActivePOI(prev => ({
        ...prev,
        labels: updatedLabels
      }));
      
      if (error.response?.status === 403) {
        alert('You do not have permission to delete this label');
      } else {
        alert('Failed to delete label');
      }
    }
  };

  // Handle close info window
  const handleCloseInfoWindow = useCallback(() => {
    if (map) {
      handleSetActivePOI(null);
    }
  }, [map, handleSetActivePOI]);

  // Handle search input
  const handleSearchInput = async (value) => {
    setSearchQuery(value.trim());
    
    if (!value.trim()) {
      setPoiResults([]);
      return;
    }

    let amapSuggestions = [];
    let labelMatchPois = [];

    // Get AMap suggestions
    if (autoCompleteRef.current) {
      amapSuggestions = await new Promise((resolve) => {
        autoCompleteRef.current.search(value, (status, result) => {
          if (status === 'complete' && result.tips) {
            resolve(result.tips.map(tip => ({
              id: tip.id,
              name: tip.name,
              district: tip.district,
              address: tip.address,
              source: 'amap'
            })));
          } else {
            resolve([]);
          }
        });
      });
    }

    // Get backend results
    try {
      const response = await axiosInstance.get(`/pois/search/?q=${encodeURIComponent(value.trim())}`);
      labelMatchPois = response.data.features?.map(poi => ({
        id: poi.properties.amap_id,
        name: poi.properties.name,
        district: poi.properties.address,
        address: poi.properties.address,
        source: 'backend',
        labels: poi.properties.labels,
        location: {
          lng: poi.geometry.coordinates[0],
          lat: poi.geometry.coordinates[1]
        }
      })) || [];
    } catch (error) {
      console.error('Backend search failed:', error);
      labelMatchPois = [];
    }

    // Combine results
    const combinedResults = [...labelMatchPois];
    
    // Get details for AMap suggestions
    await Promise.all(amapSuggestions.map(async (amapPoi) => {
      if (!combinedResults.some(poi => poi.id === amapPoi.id)) {
        try {
          const detailedPoi = await new Promise((resolve) => {
            placeSearchRef.current.getDetails(amapPoi.id, (status, result) => {
              if (status === 'complete' && result.info === 'OK' && result.poiList.pois.length > 0) {
                resolve(result.poiList.pois[0]);
              } else {
                resolve(null);
              }
            });
          });
          if (detailedPoi) {
            combinedResults.push(detailedPoi);
          }
        } catch (error) {
          console.error('Error adding AMap suggestion:', error);
        }
      }
    }));

    setPoiResults(combinedResults);
  };
         
  // Handle search
  const handleSearch = useCallback(async (query) => {
    if (placeSearchRef.current && query) {
      placeSearchRef.current.search(query, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          const amapPois = result.poiList.pois.map(poi => ({
            ...poi,
            photos: Array.isArray(poi.photos) 
              ? poi.photos.map(photo => photo.url.replace('http://', 'https://'))
              : []
          }));
          stateManagerRef.current.updateSearchResults(amapPois, handleSetActivePOI);
        } else {
          console.error('AMap search failed:', result.info);
        }
      });
    }

    try {
      const response = await axiosInstance.get(`/pois/search/?q=${encodeURIComponent(query)}`);
      if (response.data.features) {
        const backendPois = response.data.features.map(poi => ({
          id: poi.properties.amap_id,
          name: poi.properties.name,
          source: 'backend',
          location: {
            lng: poi.geometry.coordinates[0],
            lat: poi.geometry.coordinates[1]
          },
          address: poi.properties.address,
          type: poi.properties.type,
          labels: poi.properties.labels
        }));

        // Combine results, removing duplicates based on amap_id
        setPoiResults(prevResults => {
          const combinedResults = [...prevResults];
          backendPois.forEach(backendPoi => {
            if (!combinedResults.some(poi => poi.id === backendPoi.id)) {
              combinedResults.push(backendPoi);
            }
          });
          return combinedResults;
        });
      
      stateManagerRef.current.updateSearchResults(backendPois, handleSetActivePOI);

      }
    } catch (error) {
      console.error('Backend search failed:', error);
    }
  }, []);

  // Handle POI selection
  const handlePOISelect = async (poi) => {
    try {
      // If poi is from search suggestions (autocomplete), fetch full details first
      if (!poi.location) {
        if (placeSearchRef.current) {
          placeSearchRef.current.getDetails(poi.id, async (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              const poiDetails = result.poiList.pois[0];
              if (poiDetails) {
                const fullPoi = {
                  id: poiDetails.id,
                  name: poiDetails.name,
                  type: poiDetails.type,
                  location: {
                    lng: poiDetails.location.lng,
                    lat: poiDetails.location.lat
                  },
                  address: poiDetails.address,
                  adcode: poiDetails.adcode,
                  adname: poiDetails.adname,
                  citycode: poiDetails.citycode,
                  cityname: poiDetails.cityname,
                  pcode: poiDetails.pcode,
                  pname: poiDetails.pname,
                  tel: poiDetails.tel || '',
                  website: poiDetails.website || '',
                  email: poiDetails.email || '',
                  // photos: Array.isArray(poiDetails.photos) ? poiDetails.photos.map(photo => photo.url) : [],
                  photos: Array.isArray(poiDetails.photos) 
                    ? poiDetails.photos.map(photo => photo.url.replace('http://', 'https://')) 
                    : [],
                  distance: poiDetails.distance,
                  postcode: poiDetails.postcode || '',
                  shopinfo: poiDetails.shopinfo,
                  indoor_map: poiDetails.indoor_map,
                  discount: poiDetails.discount,
                  groupbuy: poiDetails.groupbuy,
                  entr_location: poiDetails.entr_location,
                  exit_location: poiDetails.exit_location,
                };
                
                const labels = await fetchLabels(fullPoi.id);
                handleSetActivePOI({
                  ...fullPoi,
                  labels: labels
                });
                setPoiResults([]); // Clear the search results
              }
            }
          });
        }
      } else {
        // If poi already has full details (from placeSearch)
        const labels = await fetchLabels(poi.id);
        handleSetActivePOI({
          ...poi,
          labels: labels
        });
        setPoiResults([]); // Clear the search results
      }
    } catch (error) {
      console.error('Error handling POI selection:', error);
      setPoiResults([]);
    }
  };

  // hooks (event listeners that depend on handlers)
  const { setupEventListeners } = useEventListeners(stateManagerRef.current, AMapAPI, placeSearchRef);

  // effects
  useEffect(() => {
    if (map && AMapAPI && placeSearchRef.current && language) {
      map.setLang(language);
      setupEventListeners(map, language);
    }
  }, [language, map, AMapAPI, setupEventListeners]);
  
  // Determine if the device is mobile
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    if (AMapAPI && map) {
      const geolocation = new AMapAPI.Geolocation({
        enableHighAccuracy: true,
        timeout: 10000,
        buttonOffset: new AMapAPI.Pixel(10, 20),
        zoomToAccuracy: true,
        buttonPosition: 'LB'
      });

      document.getElementById('customLocationButton').addEventListener('click', function() {
        geolocation.getCurrentPosition();
      });

      geolocation.on('complete', function (result) {
        map.setCenter([result.position.lng, result.position.lat]);
      });

      geolocation.on('error', function (error) {
        console.error('error:', error);
      });
    }
  }, [AMapAPI, map]);

  useEffect(() => {
    const refreshActivePOILabels = async () => {
      if (isAuthenticated && activePOI) {
        const updatedLabels = await fetchLabels(activePOI.id);
        handleSetActivePOI({
          ...activePOI,
          labels: updatedLabels
        });
      }
    };
    
    refreshActivePOILabels();
  }, [isAuthenticated]);

  // Add these new states
  const [isPhotoFullscreen, setIsPhotoFullscreen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [fullscreenPhotos, setFullscreenPhotos] = useState([]);

  // Add these states and handlers
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const SWIPE_THRESHOLD = 50;
    const swipeDistance = touchStart - touchEnd;
    
    if (Math.abs(swipeDistance) > SWIPE_THRESHOLD) {
      if (swipeDistance > 0 && selectedPhotoIndex < fullscreenPhotos.length - 1) {
        setSelectedPhotoIndex(prev => prev + 1);
      } else if (swipeDistance < 0 && selectedPhotoIndex > 0) {
        setSelectedPhotoIndex(prev => prev - 1);
      }
    }
    setTouchStart(0);
    setTouchEnd(0);
  };

  // Add this handler
  const handlePhotoFullscreen = (photos, index) => {
    setFullscreenPhotos(photos);
    setSelectedPhotoIndex(index);
    setIsPhotoFullscreen(true);
  };

  // Add this useEffect to fetch GoTags when component mounts
  useEffect(() => {
    const fetchPublicGoTags = async () => {
      try {
        const response = await axiosInstance.get('/pois/public-gotags/');
        const flattenedTags = response.data.reduce((acc, category) => {
          return [...acc, ...category.options.map(option => option.name)];
        }, []);
        setPublicGoTags(flattenedTags);
      } catch (error) {
        console.error('Error fetching public GoTags:', error);
        setPublicGoTags([]); // Set empty array as fallback
      }
    };

    fetchPublicGoTags();
  }, []);

  // render
  return (
    <div className="map-container" onClick={handleContainerClick}>
      {/* Search bar */}
      <SearchBar 
        onSearch={handleSearch}
        onInput={handleSearchInput}
        onClear={handleClear}
        placeholder="Search places or GoTags"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        hasResults={poiResults.length > 0}
        hasQuery={searchQuery.length > 0}
      />
      {/* Map container */}
      <div
        id="container"
        ref={mapRef}
        className="container"
      ></div>

      <button id="customLocationButton" className="geolocation-button">
        <i className="fas fa-location-arrow"></i>
      </button>

      {/* Info window */}
      {activePOI && (
        isMobile ? (
          <MobileInfoWindow
            poi={activePOI}
            onClose={handleCloseInfoWindow}
            onAddLabel={handleAddLabel}
            onDeleteLabel={handleDeleteLabel}
            onPhotoClick={handlePhotoFullscreen}
            publicGoTags={publicGoTags}
          />
        ) : (
          <InfoWindow
            poi={activePOI}
            onClose={handleCloseInfoWindow}
            onAddLabel={handleAddLabel}
            onDeleteLabel={handleDeleteLabel}
            onPhotoClick={handlePhotoFullscreen}
            publicGoTags={publicGoTags}
          />
        )
      )}
      {poiResults.length > 0 && (
        <div className="poi-results">
          <ul>
            {poiResults.map(poi => {
              const matchingLabels = poi.labels?.filter(label => 
                label.label.name.toLowerCase().includes(searchQuery.toLowerCase())
              ) || [];

              return (
                // when user clicks on a POI in the search results
                <li key={poi.id} onClick={() => handlePOISelect(poi)}>
                  {/* POI name */}
                  <div className="poi-result-name">
                    {poi.name}
                    {poi.source === 'backend' && matchingLabels.length > 0}
                  </div>
                  {/* POI address */}
                  <div className="poi-result-address">{poi.address || poi.district}</div>
                  {/* GoTags */}
                  {matchingLabels.length > 0 && (
                    <div className="poi-results-labels">
                      {matchingLabels.map(label => (
                        <span key={label.label.name} className="label-pill">
                          {label.label.name}
                        </span>
                      ))}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {!showLanguageSelector && (
        <button className="globe-button" onClick={toggleLanguageSelector(setShowLanguageSelector)}>
          <i className="fas fa-globe"></i>
        </button>
      )}
      {showLanguageSelector && (
        <div className="language-selector">
          <button className="close-button" onClick={toggleLanguageSelector(setShowLanguageSelector)}>
            <i className="fas fa-times"></i>
          </button>
          <h4>{t("Map Language")}</h4>
          <div>
            <label>
              <input
                type="radio" 
                name="language" 
                value="zh_cn" 
                checked={language === 'zh_cn'} 
                onChange={handleLanguageChange(dispatch, handleSetActivePOI)}
              />
              {t("CH")}
            </label>
          </div>
          <div>
            <label>
              <input 
                type="radio" 
                name="language" 
                value="en" 
                checked={language === 'en'} 
                onChange={handleLanguageChange(dispatch, handleSetActivePOI)}
              />
              {t("EN (less accurate)")}
            </label>
          </div>
          <div>
            <label>
              <input 
                type="radio" 
                name="language" 
                value="zh_en" 
                checked={language === 'zh_en'} 
                onChange={handleLanguageChange(dispatch, handleSetActivePOI)}
              />
              {t("CH/EN (less accurate)")}
            </label>
          </div>
        </div>
      )}

      {/* Add fullscreen overlay here */}
      {isPhotoFullscreen && (
        <div 
          className="fullscreen-overlay" 
          onClick={() => setIsPhotoFullscreen(false)}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="fullscreen-photos-container" onClick={e => e.stopPropagation()}>
            <div 
              className="fullscreen-photos-scroll"
              style={{ transform: `translateX(-${selectedPhotoIndex * 100}%)` }}
            >
              {fullscreenPhotos.map((photo, index) => (
                <div key={index} className="fullscreen-photo-wrapper">
                  <img src={photo} alt={`Photo ${index + 1}`} className="fullscreen-photo" />
                </div>
              ))}
            </div>
            <button 
              className="close-fullscreen-btn"
              onClick={() => setIsPhotoFullscreen(false)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
