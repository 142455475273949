import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './GoTagsSection.css';
import { useSelector } from 'react-redux';

const GoTagsSection = ({ goTags = [], isOwnProfile = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGoTags, setFilteredGoTags] = useState([]);
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const userGoTags = goTags.filter(tag => 
      isOwnProfile ? true : // If it's own profile, show all tags
      tag.created_by === user.id // Otherwise only show tags created by the user
    );
    setFilteredGoTags(userGoTags);
  }, [goTags, isOwnProfile]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = goTags.filter(tag =>
      (isOwnProfile ? true : tag.created_by === user.id) && // First check if it's the user's tag
      (tag.poi_name.toLowerCase().includes(term) ||
      tag.public_labels.some(label => label.toLowerCase().includes(term)) ||
      (isOwnProfile && tag.private_labels.some(label => label.toLowerCase().includes(term))))
    );
    setFilteredGoTags(filtered);
  };

  const handleClick = (poi) => {
    // Redirect to homepage with POI data
    console.log('Navigating with POI:', poi);
    navigate('/', { state: { selectedPOI: poi } });
  };

  return (
    <div className="go-tags-section">
    {/* Search */}
      <div className="search-profile-gotags-container">
        <Input
          placeholder="Search by places or GoTags"
          value={searchTerm}
          onChange={handleSearch}
          className="search-profile-gotags-input"
        />
        <button className="search-profile-gotags-btn">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <div className="go-tags-section-content">
        <div className="go-tags-list">
          {filteredGoTags.length > 0 ? (
            filteredGoTags.map((tag, index) => (
              <div key={index} className="go-tag-item">
                <h3 onClick={() => handleClick(tag)}>{tag.poi_name}</h3> {/* POI name */}
                {/* POI's labels */}
                <div>
                    {/* Public labels */}
                  <strong>Public: </strong>
                  {tag.public_labels.length > 0 ? (
                    tag.public_labels.map((label, idx) => (
                      <span key={idx} className="label-pill public" onClick={() => handleClick(tag)}>{label}</span>
                    ))
                  ) : (
                    <span>No public GoTags</span>
                  )}
                </div>
                {isOwnProfile && (
                  <div>
                    <strong>Private: </strong>
                    {tag.private_labels.length > 0 ? (
                      tag.private_labels.map((label, idx) => (
                        <span key={idx} className="label-pill private" onClick={() => handleClick(tag)}>{label}</span>
                      ))
                    ) : (
                      <span>No private GoTags</span>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no-gotags-message">
              <p>{isOwnProfile ? 'Add your own GoTags!' : 'No GoTags yet'}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoTagsSection;