import { useState, useEffect, useRef } from 'react';
import AMapLoader from "@amap/amap-jsapi-loader";

const SECURITY_JS_KEY = process.env.REACT_APP_AMAP_SECURITY_JS_KEY;
const AMAP_KEY = process.env.REACT_APP_AMAP_KEY;
const AMAP_VERSION = "1.4.15";

const PLACE_SEARCH_PARAMS = {
  pageSize: 20,
  pageIndex: 1,
  extensions: 'all',
  sortRule: 'weight',
  renderStyle: 'newpc',
  autoFitView: true,
  citylimit: true,
  city: '010',
  // lang: 'en',
  lang: 'zh_cn',
};

const AUTO_COMPLETE_PARAMS = {
  city: '010', // Search nationwide
  citylimit: true,
  datatype: 'all',
  extensions: 'all'
};

export function useMapSetup(language) {
    const [AMapAPI, setAMapAPI] = useState(null);
    const [map, setMap] = useState(null);
    const placeSearchRef = useRef(null);
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        window._AMapSecurityConfig = {
          securityJsCode: SECURITY_JS_KEY,
        };
    
        AMapLoader.load({
          key: AMAP_KEY, // ����õ�Web�˿�����Key���״ε��� load ʱ����
          version: AMAP_VERSION, // ָ��Ҫ���ص� JSAPI �İ汾��ȱʡʱĬ��Ϊ 1.4.15
          plugins: ["AMap.Scale", "AMap.Geolocation", "AMap.PlaceSearch", "AMap.InfoWindow", "AMap.Autocomplete"], //��Ҫʹ�õĵĲ���б����������'AMap.Scale'��֧�����Ӷ���磺['...','...']
        })
          .then((AMap) => {
            setAMapAPI(AMap);
            const geolocation = new AMap.Geolocation({
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0,
              convert: true,
              showMarker: true,
              showCircle: false,
              showButton: false,
              panToLocation: true,
              zoomToAccuracy: true,
              buttonOffset: new AMap.Pixel(10, 20),
              buttonPosition: 'RB',
              GeoLocationFirst: true, // Prioritize HTML5 Geolocation
              useNative: true, // Use native geolocation on mobile
              isHotspot: true
            });
    
            const newMap = new AMap.Map("container", {
              // ���õ�ͼ����id
              viewMode: "3D", // �Ƿ�Ϊ3D��ͼģʽ
              zoom: 15, // ��ʼ����ͼ����
              lang: language,
              isHotspot: true
            });
    
            newMap.addControl(geolocation);
    
            geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                const { lng, lat } = result.position;
                newMap.setCenter([lng, lat]);
              } else {
                console.error('Failed to get current position:', result);
              }
            });

            placeSearchRef.current = new AMap.PlaceSearch(PLACE_SEARCH_PARAMS);
            autoCompleteRef.current = new AMap.Autocomplete(AUTO_COMPLETE_PARAMS);

            placeSearchRef.current.map = newMap;
    
            setMap(newMap);
          })
          .catch((e) => {
            console.error(e);
          });
    
        return () => {
          map?.destroy();
        };
      }, [AMapAPI, language]); // adding AMapAPI as a dependency is important to avoid stale closure

    return { map, AMapAPI, placeSearchRef, autoCompleteRef }
}