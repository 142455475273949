import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faLock,
  faLanguage,
  faEye,
  faSignOutAlt,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import EditProfile from '../../components/EditProfile/EditProfile';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/langSlice';
import axiosInstance from '../../api/axiosInstance';
import { updateUser, logout } from '../../store/authSlice';
import { useLocation } from 'react-router-dom';
import './MobileSettings.css';
import PasswordManagement from '../../components/PasswordManagement/PasswordManagement';

function MobileSettings() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const initialMenu = location.pathname.split('/')[2] || 'menu'; // Get the initial menu item from the URL
  const [activeMenu, setActiveMenu] = useState(initialMenu); // State for active menu item
  const { user } = useSelector(state => state.auth);
  const language = useSelector(state => state.lang.language);
  const [isPrivate, setIsPrivate] = useState(false);

  const menuGroups = [
    {
      title: t('Account Settings'),
      items: [
        { id: 'edit_profile', label: t('Edit Profile'), icon: faUser },
        { id: 'password_management', label: t('Password Management'), icon: faLock },
        { id: 'language', label: t('Language'), icon: faLanguage },
      ]
    },
    // {
    //   title: t('Privacy and Security'),
    //   items: [
    //     { id: 'account_privacy', label: t('Account Privacy'), icon: faEye },
    //   ]
    // },
    {
      title: t('Other'),
      items: [
        { id: 'logout', label: t('Log Out'), icon: faSignOutAlt, color: 'red' }
      ]
    }
  ];

  const handleMenuClick = (itemId) => {
    if (itemId === 'logout') {
      handleLogout();
    } else {
      setActiveMenu(itemId);
    }
  };

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      await axiosInstance.post('/users/logout/', { refresh: refreshToken });
      dispatch(logout());
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      dispatch(logout());
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/');
    }
  };

  const changeLanguage = (newLanguage) => {
    dispatch(setLanguage(newLanguage));
    i18n.changeLanguage(newLanguage);
  };

  const togglePrivacy = async () => {
    try {
      const newPrivacyStatus = !isPrivate;
      setIsPrivate(newPrivacyStatus);
      const response = await axiosInstance.patch('/users/profile/account_privacy/', { is_private: newPrivacyStatus });
      dispatch(updateUser(response.data));
    } catch (error) {
      console.error('Failed to update privacy status:', error);
      setIsPrivate(!isPrivate);
    }
  };

  return (
    <div className="mobile-settings">
      {activeMenu === 'menu' ? (
        <>
          <div className="settings-header">
            <button onClick={() => navigate('/profile')} className="back-btn">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h1 className="settings-title">{t('Settings and activity')}</h1>
          </div>
          <div className="menu-list">
            {menuGroups.map((group, index) => (
              <div key={index} className="menu-group">
                <h3 className="menu-group-title">{group.title}</h3>
                {group.items.map(item => (
                  <button
                    key={item.id}
                    className="menu-item"
                    onClick={() => handleMenuClick(item.id)}
                    style={{ color: item.color || 'inherit' }}
                  >
                    <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                    {item.label}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="content">
          <div className="settings-header">
            <button onClick={() => setActiveMenu('menu')} className="back-btn">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h1 className="settings-title">
              {activeMenu === 'edit_profile' && t('Edit Profile')}
              {activeMenu === 'password_management' && t('Password Management')}
              {activeMenu === 'language' && t('Language')}
              {activeMenu === 'account_privacy' && t('Account Privacy')}
            </h1>
          </div>
          <div className="content-details">
            {activeMenu === 'edit_profile' && (
              <div>
                <EditProfile 
                  dispatch={dispatch}
                  initialValues={{
                    display_name: user?.display_name,
                    bio: user?.bio,
                    country: user?.country,
                    language: user?.language,
                    food_preferences: Array.isArray(user?.food_preferences) 
                      ? user.food_preferences
                      : JSON.parse(user?.food_preferences || '[]'),
                    travel_preferences: Array.isArray(user?.travel_preferences) 
                      ? user.travel_preferences
                      : JSON.parse(user?.travel_preferences || '[]'),
                  }}
                />
              </div>
            )}
            {activeMenu === 'password_management' && (
              <div>
                <PasswordManagement />
              </div>
            )}
            {activeMenu === 'language' && (
              <div>
                <p>{t('See buttons, titles, and other texts on ChinaGO in your preferred language.')}</p>
                <select
                  className="select-language"
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  <option value="en">{t('English')}</option>
                  <option value="zh">{t('Chinese')}</option>
                </select>
              </div>
            )}
            {activeMenu === 'account_privacy' && (
              <div>
                <div className="privacy-setting">
                  <p>{t('When your account is public, your profile and posts can be seen by anyone, on or off ChinaGO, even if they don\'t have an ChinaGO account. When your account is private, only the followers you approve can see what you share, including your photos or videos on hashtag and location pages, and your followers and following lists. Certain information on your profile, like your profile picture and username, is visible to everyone on and off ChinaGO.')}</p>
                  <label className="switch">
                    <input type="checkbox" checked={isPrivate} onChange={togglePrivacy} />
                    <span className="slider round"></span>
                  </label>
                </div>  
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileSettings;