import { createSlice } from '@reduxjs/toolkit';

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  // initial state
  initialState: {
    isAuthenticated: false, // whether the user is authenticated
    user: null, // stores user data
    loading: true, // tracks whether the user data is loading
  },
  reducers: {
    // Set the authentication state
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.loading = false;
    },
    // Logout the user
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('authToken'); // Clear token on logout
      state.loading = false;
    },
    // Update the user data
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setAuth, logout, updateUser } = authSlice.actions;
export default authSlice.reducer;