import axiosInstance from '../../../api/axiosInstance';

// Get the top label by count
export const getTopLabel = (labels) => {
  if (!labels || labels.length === 0) return null;
  
  // Sort labels by count in descending order
  const sortedLabels = [...labels].sort((a, b) => b.count - a.count);
  return sortedLabels[0];
};

// Fetch labels for a POI
export const fetchLabels = async (poiId) => {
  try {
    const response = await axiosInstance.get(`/pois/${poiId}/labels/`);
		console.log("fetchLabels response:", response);
    return response.data;
  } catch (error) {
    console.error('Error fetching labels:', error);
    return [];
  }
};

// Add a label to a POI
export const addLabel = async (poiId, labelName, poiData, isPublic) => {
  try {
    let poiExists = false; // Whether the POI exists
    try {
      await axiosInstance.get(`/pois/${poiId}/`); // Check if the POI exists
      poiExists = true;
    } catch (error) {
      if (error.response && error.response.status === 404) {
				console.log("POI doesn't exist!");
        // POI doesn't exist, create it first
        if (!poiData) {
          throw new Error('POI data is required to create a new POI');
        }
				console.log("POI data:", poiData);
        // Try to create the POI
        try {
          await createPOIWithLabels(poiData);
          poiExists = true;
					console.log("POI created successfully, poiExists is now:", poiExists);
        } catch (error) {
					if (error.response && error.response.status === 200) {
						// POI already exists (race condition handled)
						poiExists = true;
						console.log("POI already exists (race condition handled), poiExists is now:", poiExists);
					} else {
						throw error;
          }
        }
      } else {
        throw error;
      }
    }
		if (poiExists) {
      // Add the label to the POI
      const response = await axiosInstance.post(`/pois/${poiId}/labels/`, {
        name: labelName,
        is_public: isPublic,
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error in addLabel:', error);
    throw error;
  }
};

// Delete a label from a POI
export const deleteLabel = async (poiId, labelId) => {
  try {
    await axiosInstance.delete(`/pois/${poiId}/labels/${labelId}/`);
    return true;
  } catch (error) {
    console.error('Error deleting label:', error);
    throw error;
  }
};

// Create a POI with labels
export const createPOIWithLabels = async (poiData) => {
  try {
    const response = await axiosInstance.post('/pois/create/', poiData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 200) {
      // POI already exists, this is fine
			console.log("POI already exists, this is fine");
      return error.response.data;
    }
    console.error('Error creating POI with labels:', error);
    throw error;
  }
};