class StateManager {
    constructor(markerManager, setActivePOI) {
      console.log('StateManager constructor called with:', {
        hasMarkerManager: !!markerManager,
        hasSetActivePOI: !!setActivePOI
      });
      this.markerManager = markerManager;
      this.setState = setActivePOI;
      this.currentPOI = null;
    }
  
    async setActivePOI(poi, labels = null) {
      console.log('setActivePOI called with:', poi);
      // Add labels if provided
      if (labels) {
        poi.labels = labels;
      }

      // Clear previous state
      if (this.currentPOI) {
        this.markerManager.setActiveMarker(null);
      }

      // Update current POI
      this.currentPOI = poi;
      console.log("currentPOI", this.currentPOI);
  
      if (poi) {
        // Set new marker and state
        this.markerManager.setActiveMarker(poi);
        this.setState(poi);
      } else {
        this.currentPOI = null;
        this.setState(null);
      }
    }
  
    // Update search results
    updateSearchResults(pois, onMarkerClick) {
      this.markerManager.addSearchResultMarkers(pois, onMarkerClick);
    }
  
    // Clear all markers and reset current POI
    clearAll() {
      this.markerManager.clearAll();
      this.currentPOI = null;
      this.setActivePOI(null);
    }
  }
  
  export default StateManager;