import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Checkbox, Input } from 'antd';
import './AuthModal.css';
import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';
import { useDispatch  } from 'react-redux';
import {  setAuth } from '../../store/authSlice';
import { countryOptions } from '../../constants/countryOptions';
import { useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../../assets/images/google-icon.png';
import wechatIcon from '../../assets/images/wechat-icon.png';

// AuthModal component
const AuthModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    display_name: '',
    password: '',
    password_confirmation: '',
    bio: '',
    country: '',
    language: '',
    profile_picture: null,
    food_preferences: [],
    travel_preferences: [],
  });

  const [loginError, setLoginError] = useState(''); // State to hold login error message
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [checkingUsername, setCheckingUsername] = useState(false);

  // Signup credentials
  const [signupCredentials, setSignupCredentials] = useState(null);
  const [autoLogin, setAutoLogin] = useState(false);

  const [form] = Form.useForm();  // Add this line to get form instance

    // Add this useEffect to set the form values when autoLogin is true
    useEffect(() => {
        if (autoLogin && signupCredentials) {
            form.setFieldsValue({
                username: signupCredentials.username,
                password: signupCredentials.password
            });
            setAutoLogin(false);  // Reset autoLogin after setting values
        }
    }, [autoLogin, signupCredentials, form]);

  // Handle image upload
  const handleImageUpload = (e) => {
    setFormData({ ...formData, profile_picture: e.target.files[0] });
  };

  // Fetch preferences
  const [preferences, setPreferences] = useState({
    food: [],
    travel: []
  });

  useEffect(() => {
      const fetchPreferences = async () => {
          try {
              const response = await axiosInstance.get('preferences/get_signup_preferences/');
              setPreferences(response.data);
          } catch (error) {
              console.error('Error fetching preferences:', error);
              if (error.response) {
                  console.log('Error status:', error.response.status);
                  console.log('Error data:', error.response.data);
                  console.log('Error headers:', error.response.headers);
              } else if (error.request) {
                  console.log('Request made but no response:', error.request);
              } else {
                  console.log('Error setting up request:', error.message);
              }
          }
      };
      
      fetchPreferences();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    // Check username availability with debounce
    if (name === 'username') {
      // Clear any existing timeout
      if (window.usernameTimeout) {
        clearTimeout(window.usernameTimeout);
      }
      // Set new timeout to check username after user stops typing
      window.usernameTimeout = setTimeout(() => {
        checkUsername(value);
      }, 500); // Wait 500ms after user stops typing
    } 
  };

  // Fix the food preferences checkbox group handler
  const handleFoodPreferencesChange = (checkedValues) => {
    setFormData(prevData => ({
        ...prevData,
        food_preferences: checkedValues
    }));
  };

  const handleTravelPreferencesChange = (checkedValues) => {
    setFormData(prevData => ({
        ...prevData,
        travel_preferences: checkedValues
    }));
  };

  // Check if username is available
  const checkUsername = async (username) => {
    if (!username) {
      setUsernameAvailable(true);
      return;
    }
    
    setCheckingUsername(true);
    try {
      const response = await axiosInstance.post('users/check-username/', 
        { username },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setUsernameAvailable(response.data.available);
    } catch (error) {
      console.error('Error checking username:', error);
      setUsernameAvailable(false);
    } finally {
      setCheckingUsername(false);
    }
  };

  const handleGoogleSuccess = async (tokenResponse) => {
    try {
      console.log('Google response:', tokenResponse);
      console.log('Current origin:', window.location.origin);

      // Get user info using the access token
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      
      const userInfo = await userInfoResponse.json();

      // Send to your backend
      const googleCallbackUri = process.env.REACT_APP_GOOGLE_CALLBACK_URI;
      const loginResponse = await axios.post(googleCallbackUri, {
        email: userInfo.email,
        name: userInfo.name,
        picture: userInfo.picture,
        // You can also send the access_token if needed
        access_token: tokenResponse.access_token,
        id_token: tokenResponse.id_token
      });

      // Handle the response
      localStorage.setItem('token', loginResponse.data.access);
      localStorage.setItem('refreshToken', loginResponse.data.refresh);

      const userResponse = await axiosInstance.get('users/profile/me/', {
        headers: {
          'Authorization': `Bearer ${loginResponse.data.access}`
        }
      });
      const userData = userResponse.data;
      console.log('Profile picture URL from backend:', userResponse.data.profile_picture);

      localStorage.setItem('userData', JSON.stringify(userData));
      dispatch(setAuth({isAuthenticated: true, user: userData}));
      onClose();
    } catch (error) {
      console.error('Google login error:', error);
    }
  };

  const handleGoogleFailure = (response) => {
    console.error('Google login failed:', response);
  };

  // using useGoogleLogin hook, so provides access token
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleFailure,
    scope: 'email profile',
  });

  // Handle form submission
  const handleSubmit = async (values) => {

    // username availability
    if (!isLogin && currentStep === 0 && !usernameAvailable) {
      alert('Please choose a different username');
      return;
    }

    // Login
    if (isLogin) {
        try {
          setLoginError(''); // Clear any previous errors
            console.log('Logging in with:', values);
            const loginResponse = await axiosInstance.post('users/login/', {
                username: values.username,
                password: values.password,
            });

            // If login is successful, store complete user data
            if (loginResponse.data.access) {
              localStorage.setItem('token', loginResponse.data.access);
              localStorage.setItem('refreshToken', loginResponse.data.refresh);

              // makes an API request to fetch the user's profile data after they successfully log in
              const userResponse = await axiosInstance.get('users/profile/me/', {
                headers: {
                  'Authorization': `Bearer ${loginResponse.data.access}`
                }
              });
              
              const userData = userResponse.data;
              localStorage.setItem('userData', JSON.stringify(userData));

              // Dispatch the setAuth action to update the Redux state
              dispatch(setAuth({isAuthenticated: true, user: userData}));

              setSignupCredentials(null);
              onClose(); // Close the modal after successful login
            }
        } catch (error) {
            console.error('Login error:', error.response?.data || error);
            setLoginError('Invalid username or password. Please try again.');
        }
    } 
    
    // Registration
    else {
        if (currentStep < 3) {
            // Just move to next step if not on final step
            try {
              await form.validateFields();
              
              // Step-specific validation
              if (currentStep === 0) {
                // First step validation (account details)
                const fields = ['email', 'username', 'display_name', 'password', 'password_confirmation'];
                await form.validateFields(fields);
              } 
              else if (currentStep === 1) {
                // Second step validation (location and language)
                const fields = ['country', 'language'];
                await form.validateFields(fields);
              }
              // Food preferences (step 2) and travel preferences (step 3) are optional
              
              // If validation passes, move to next step
              setCurrentStep(currentStep + 1);
            } catch (errorInfo) {
              console.log('Validation failed:', errorInfo);
              return;
            }
        } else {
            // On final step, submit registration
            try {
              const registrationData = new FormData();
              registrationData.append('email', formData.email);
              registrationData.append('username', formData.username);
              registrationData.append('display_name', formData.display_name);
              registrationData.append('password', formData.password);
              registrationData.append('password_confirmation', formData.password_confirmation);
              registrationData.append('bio', formData.bio);
              registrationData.append('country', formData.country);
              registrationData.append('language', formData.language);

              if (formData.profile_picture) {
                registrationData.append('profile_picture', formData.profile_picture);
              }

              // Register the user
              const registerResponse = await axiosInstance.post('users/register/', registrationData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
              });

              // If registration successful, log in automatically
              const loginResponse = await axiosInstance.post('users/login/', {
                username: formData.username,
                password: formData.password
              });

              // Store tokens
              localStorage.setItem('token', loginResponse.data.access);
              localStorage.setItem('refreshToken', loginResponse.data.refresh);

              // Update user preferences
              if (formData.food_preferences?.length || formData.travel_preferences?.length) {
                try {
                  console.log('Updating user preferences...');
                  const allPreferences = [
                    ...(formData.food_preferences || []),
                    ...(formData.travel_preferences || [])
                  ];
                  console.log('All preferences:', allPreferences);

                  const response = await axiosInstance.post(
                    'preferences/update_user_preferences/', 
                    { preferences: allPreferences },
                    { 
                      headers: {
                        'Authorization': `Bearer ${loginResponse.data.access}`,
                      },
                    }
                  );
                  console.log('Preferences update response:', response);

                } catch (error) {
                  console.error('Failed to update preferences:', error);
                }
              }

              // Fetch updated user data
              const userResponse = await axiosInstance.get('users/profile/me/', {
                headers: {
                  'Authorization': `Bearer ${loginResponse.data.access}`
                }
              });

              const userData = userResponse.data;
              localStorage.setItem('userData', JSON.stringify(userData));
              dispatch(setAuth({isAuthenticated: true, user: userData}));

              // Clear form and close modal
              setFormData({
                username: '',
                email: '',
                display_name: '',
                password: '',
                password_confirmation: '',
                bio: '',
                country: '',
                language: '',
                profile_picture: null,
                food_preferences: [],
                travel_preferences: []
              });

              setIsLogin(true);
              setCurrentStep(0);
              onClose();
            } catch (error) {
              console.error('Signup failed:', error.response?.data || error);
              alert('Registration failed. Please try again.');
            }
        }
    }
};

  // Add this useEffect to log when currentStep changes
  useEffect(() => {
    console.log('currentStep changed to:', currentStep);
  }, [currentStep]);

  const renderLoginForm = () => (
      <Form 
      form={form}  // Add this prop
        onFinish={handleSubmit}
      >
        {/* Normal Login and Signup*/}
        {loginError && <div className="login-error">{loginError}</div>}
        <Form.Item
          name="username"
          label="Username or Email"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        {/* <div className="login-options">
          <a href="/forgot-password" className="forgot-password-link">Forgot Password?</a>
        </div> */}
        
        <Button type="primary" htmlType="submit">
          Log In
        </Button>

        <Button onClick={() => {
          setLoginError(''); // Clear any error messages
          setIsLogin(false);
        }}>
          Sign Up
        </Button>

        {/* Divider for social logins */}
        <div className="social-login-divider">
          <span>OR</span>
        </div>

        {/* Social login buttons (Google and WeChat) */}
        <div className="social-login-buttons">
          <Button 
            className="google-button" 
            icon={<img src={googleIcon} alt="Google" />}
            onClick={() => {handleGoogleLogin()}}
          >
            Google
          </Button>
          {/* <Button 
            className="wechat-button"
            icon={<img src={wechatIcon} alt="WeChat" />}
            onClick={() => {/* Handle WeChat login */ }
          {/* > */}
            {/* WeChat */}
          {/* </Button> */}
        </div>
      </Form>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Form>
              <div className="form-content">
                <Form.Item 
                  label="Email" 
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email address' },
                    { max: 255, message: 'Email cannot exceed 255 characters' },
                  ]}
                >
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: 'Please input your username!' },
                    { pattern: /^\S*$/, message: 'Username cannot contain spaces' },
                    { min: 3, message: 'Username must be at least 3 characters' },
                    { max: 20, message: 'Username cannot exceed 20 characters' },
                  ]}
                  help={
                    // Only show availability status if username is valid (no validation errors)
                    formData.username && 
                    formData.username.length >= 3 && 
                    formData.username.length <= 20 && 
                    !formData.username.includes(' ') 
                      ? (
                        checkingUsername 
                          ? 'Checking availability...'
                          : usernameAvailable 
                            ? 'Username is available' 
                            : 'Username is already taken'
                      )
                      : null  // Don't show any help text when there are validation errors
                  }
                >
                  <Input
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item 
                  label="Display Name" 
                  name="display_name"
                  rules={[{ required: true, message: 'Please input your display name!' }]}
                >
                  <Input
                    name="display_name"
                    value={formData.display_name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item 
                  label="Password" 
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                    { min: 8, message: 'Password must be at least 8 characters' },
                    { max: 20, message: 'Password cannot exceed 20 characters' },
                    {
                      validator: (_, value) => {
                        if (value && value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/)) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Password must contain uppercase, lowercase and numbers');
                      }
                    }
                  ]}
                >
                  <Input.Password
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item 
                  label="Confirm Password" 
                  name="password_confirmation"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (value === getFieldValue('password')) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The passwords do not match!');
                      }
                    })
                  ]}
                >
                  <Input.Password
                    name="password_confirmation"
                    value={formData.password_confirmation}
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Profile Picture">
                  <input type="file" name="profile_picture" onChange={handleImageUpload} />
                </Form.Item>
              </div>
            </Form>
            <div className="form-actions">
              <Button onClick={() => setIsLogin(true)}>Back to Login</Button>
              <Button type="primary" onClick={handleSubmit}>Next</Button>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <Form>
              <div className="form-content">
                <Form.Item 
                  label="Country" 
                  name="country"
                  rules={[{ required: true, message: 'Please select your country!' }]}
                >
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>Select a country</option>
                    {countryOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Form.Item>
                <Form.Item 
                  label="Preferred Language" 
                  name="language"
                  rules={[{ required: true, message: 'Please select your preferred language!' }]}
                >
                  <select
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                    defaultValue=""
                  >
                    <option value="" disabled>Select a language</option>
                    <option value="English">English</option>
                    <option value="Chinese">Chinese</option>
                  </select>
                </Form.Item>
              </div>
              <div className="form-actions">
                <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                <Button type="primary" onClick={handleSubmit}>Next</Button>
              </div>
            </Form>
          </>
        );
      case 2:
        return (
          <>
            <Form>
              <div className="form-content">
                <h3 className="preference-title">Food Preferences</h3>
                <Checkbox.Group
                  value={formData.food_preferences}
                  onChange={handleFoodPreferencesChange}
                >
                  {preferences.food?.map(category => (
                    <div key={category.id} className="preference-category">
                      <h4>{category.name}</h4>
                      <div className="preference-options">
                        {category.options?.map(option => (
                          <Checkbox 
                            key={option.id} 
                            value={option.id}
                          >
                            {option.name}
                          </Checkbox>
                        ))}
                      </div>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
              <div className="form-actions">
                <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
              </div>
            </Form>
          </>
        );
      case 3:
        return (
          <>
            <Form>
              <div className="form-content">
                <h3 className="preference-title">Travel Preferences</h3>
                <Checkbox.Group
                  value={formData.travel_preferences}
                  onChange={handleTravelPreferencesChange}
                >
                  {preferences.travel?.map(category => (
                    <div key={category.id} className="preference-category">
                      <h4>{category.name}</h4>
                      <div className="preference-options">
                        {category.options?.map(option => (
                          <Checkbox 
                            key={option.id} 
                            value={option.id}
                          >
                            {option.name}
                          </Checkbox>
                        ))}
                      </div>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
              <div className="form-actions">
                <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>
                <Button type="primary" onClick={handleSubmit}>Submit</Button>
              </div>
            </Form>
          </>
        );
      default:
        return null;
    }
  };

  const renderQuestionnairePrompt = () => {
    return (
      <div className="questionnaire-prompt">
        <h3>One Last Step!</h3>
        <p>Please help us improve our service by completing a quick questionnaire.</p>
        <div className="form-actions">
          <Button type="primary" onClick={() => {
            window.open('https://wj.qq.com/s2/15944230/0ae3/', '_blank');
            setIsLogin(true);
            setCurrentStep(0);
            setShowQuestionnaire(false);
            onClose();
          }}>Go to Questionnaire</Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      title={showQuestionnaire ? 'Questionnaire' : (isLogin ? 'Log In' : (currentStep === 0 ? 'Sign Up' : 'Complete Your Profile'))}
      width={400}
      className="auth-modal"
      styles={{ height: '400px', overflowY: 'hidden' }}
    >
      <div className="auth-modal-content">
        {showQuestionnaire ? renderQuestionnairePrompt() : (isLogin ? renderLoginForm() : renderStepContent())}
      </div>
    </Modal>
  );
};

export default AuthModal;