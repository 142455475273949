import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Checkbox, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../api/axiosInstance';
import { countryOptions } from '../../constants/countryOptions';
import { updateUser } from '../../store/authSlice';
import './EditProfile.css';
import { useNavigate } from 'react-router-dom';

const EditProfile = ({ dispatch, initialValues }) => {
  console.log('Initial Values:', initialValues);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [preferences, setPreferences] = useState({ food: [], travel: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await axiosInstance.get('preferences/get_signup_preferences/');
        setPreferences(response.data);
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };
    fetchPreferences();
  }, []);

  const checkUsername = async (username) => {
    if (!username) {
      setUsernameAvailable(true);
      return;
    }
    
    setCheckingUsername(true);
    try {
      const response = await axiosInstance.post('users/check-username/', 
        { username },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setUsernameAvailable(response.data.available);
    } catch (error) {
      console.error('Error checking username:', error);
      setUsernameAvailable(false);
    } finally {
      setCheckingUsername(false);
    }
  };

  // Prevent automatic upload
  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    maxCount: 1,
    listType: "picture",
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      if (!usernameAvailable) {
        message.error('Username is already taken. Please choose another one.');
        return;
      }
      
      const formData = new FormData();
      
      if (values.profile_picture?.fileList?.[0]?.originFileObj) {
        formData.append('profile_picture', values.profile_picture.fileList[0].originFileObj);
      }

      Object.keys(values).forEach(key => {
        if (key !== 'profile_picture' && values[key] !== undefined) {
          if (key === 'food_preferences' || key === 'travel_preferences') {
            formData.append(key, JSON.stringify(values[key]));
          } else {
            formData.append(key, values[key]);
          }
        }
      });

      formData.append('username', values.username);

      const response = await axiosInstance.put(`users/profile/me/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      const allPreferences = [
        ...(values.food_preferences || []),
        ...(values.travel_preferences || [])
      ];

      await axiosInstance.post('preferences/update_user_preferences/', 
        { preferences: allPreferences },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );

      const refreshResponse = await axiosInstance.get('users/profile/me/');

      dispatch(updateUser(refreshResponse.data));
      message.success('Profile updated successfully');

      // Navigate to profile page
      navigate('/profile');

    } catch (error) {
      console.error('Update failed:', error);
      message.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-profile-form-container">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...initialValues,
          // food_preferences: initialValues.food_preferences.map(pref => pref.id),
          // travel_preferences: initialValues.travel_preferences.map(pref => pref.id),
        }}
        className="edit-profile-form"
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[
            { required: true, message: 'Please enter your username' },
            { pattern: /^\S*$/, message: 'Username cannot contain spaces' },
            { min: 3, message: 'Username must be at least 3 characters' },
            { max: 20, message: 'Username cannot exceed 20 characters' },
          ]}
          help={
            checkingUsername 
              ? 'Checking availability...'
              : usernameAvailable 
                ? 'Username is available' 
                : 'Username is already taken'
          }
        >
          <Input onChange={(e) => checkUsername(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="display_name"
          label="Display Name"
          rules={[{ required: true, message: 'Please enter your display name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="profile_picture"
          label="Profile Picture"
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Change Photo</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="bio"
          label="Bio"
        >
          <Input.TextArea rows={4} placeholder="Tell us about yourself" />
        </Form.Item>

        <Form.Item
          name="country"
          label="Location"
        >
          <select>
            {countryOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Form.Item>

        <Form.Item
          name="language"
          label="Language"
        >
          <select>
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
          </select>
        </Form.Item>

        <Form.Item
          name="food_preferences"
          label="Food Preferences"
        >
          <Checkbox.Group>
            {preferences.food.map(category => (
              <div key={category.id} className="preference-category">
                <h4>{category.name}</h4>
                <div className="preference-options">
                  {category.options.map(option => (
                    <Checkbox key={option.id} value={option.id}>
                      {option.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          name="travel_preferences"
          label="Travel Preferences"
        >
          <Checkbox.Group>
            {preferences.travel.map(category => (
              <div key={category.id} className="preference-category">
                <h4>{category.name}</h4>
                <div className="preference-options">
                  {category.options.map(option => (
                    <Checkbox key={option.id} value={option.id}>
                      {option.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item>
          <Button 
            type="primary" 
            onClick={handleSubmit}
            loading={loading}
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditProfile;