import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isAuthModalOpen: false,
        isMoreDropdownOpen: false,
    },
    reducers: {
        setIsAuthModalOpen: (state, action) => {
            state.isAuthModalOpen = action.payload;
        },
        setIsMoreDropdownOpen: (state, action) => {
            state.isMoreDropdownOpen = action.payload;
        },
    },
});

export const { setIsAuthModalOpen, setIsMoreDropdownOpen } = modalSlice.actions;
export default modalSlice.reducer;