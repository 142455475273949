import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tabs, Spin, message, Badge } from 'antd';
import { EditOutlined, EnvironmentOutlined, GlobalOutlined, SettingOutlined, LockOutlined, BellOutlined } from '@ant-design/icons';
import defaultProfilePic from '../../assets/images/default_profile_pic.png';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import GoTagsSection from './GoTagsSection';
import axiosInstance from '../../api/axiosInstance';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import UserPosts from './UsersPost';

function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useSelector(state => state.auth); // get user data from Redux store
  const [activeTab, setActiveTab] = useState('go-tags');
  const [loading, setLoading] = useState(true);
  const [userGoTags, setUserGoTags] = useState([]);
  const { userId } = useParams();
  const [profileUser, setProfileUser] = useState(null); // The user being viewed
  const [canViewProfile, setCanViewProfile] = useState(false); // Whether the current user can view the profile
  const [isFollowing, setIsFollowing] = useState(false); // Whether the current user is following the profile user
  const [followRequestStatus, setFollowRequestStatus] = useState(null); // Whether the current user has a pending follow request to the profile user
  const [notificationCount, setNotificationCount] = useState(0); // Number of notifications for the current user
  const [displayUser, setDisplayUser] = useState(profileUser || user);
  const dispatch = useDispatch();

  // Navigate back to discover page or followers/following list
  const handleBackClick = () => {
    const path = location.pathname;
    // If we came from followers or following list, go back to that list
    if (location.state?.from === 'followers' || location.state?.from === 'following') {
      navigate(-1);
    } else {
      // Otherwise go back to discover page
      navigate('/discover', { 
        state: { previousSearch: location.state?.previousSearch || '' } 
      });
    }
  };

  // Toggle follow status
const handleFollowToggle = async () => {
  try {
    if (isFollowing) { // If the current user is following the profile user
      const response = await axiosInstance.post(`/users/profile/${userId}/unfollow/`);
      if (response.status === 200) {
        setIsFollowing(false);
        setFollowRequestStatus(null);
        message.success('Unfollowed successfully');
      }
    } else if (profileUser?.is_private) { // If the profile user is private
      const response = await axiosInstance.post(`/users/profile/${userId}/follow_request/`);
      if (response.status === 200) {
        setFollowRequestStatus('pending');
        message.success('Follow request sent');
      }
    } else { // If the profile user is not private
      const response = await axiosInstance.post(`/users/profile/${userId}/follow/`);
      if (response.status === 200) {
        setIsFollowing(true);
        message.success('Followed successfully');
      }
    }
    
    // Get the updated profile user data
    const updatedProfile = await axiosInstance.get(`/users/profile/${userId}/`);
    setProfileUser(updatedProfile.data);
    setDisplayUser(updatedProfile.data);
    
  } catch (error) {
    console.error('Error:', error)  ;
    message.error('Action failed');
  }
};  

  // Get the text for the follow button
  const getFollowButtonText = () => {
    if (followRequestStatus === 'pending') {
      return 'Requested';
    }
    return isFollowing ? 'Unfollow' : 'Follow';
  };

  // Fetch user's GoTags and POIs associated with each GoTag
  useEffect(() => {
    if (user) {  // Remove the privacy check since we always want to fetch our own GoTags
      const endpoint = userId && userId !== user?.id 
        ? `/users/profile/${userId}/user_gotags/`
        : '/users/profile/gotags/';  // This endpoint should return both public and private GoTags for the current user

      axiosInstance.get(endpoint)
        .then(response => {
          setUserGoTags(response.data);
          setLoading(false);
        })
        .catch(error => {
          if (error.response?.status === 403) {
            setUserGoTags([]);
          } else {
            console.error('Error fetching user GoTags and POIs:', error);
            message.error('Failed to load GoTags and POIs');
          }
          setLoading(false);
        });
    }
  }, [user, userId, profileUser]);

  // Fetch profile user data
  useEffect(() => {
    // if userId is present and userId is not the same as the current user, fetch the profile user data
    if (userId && userId !== user?.id) {
      axiosInstance.get(`/users/profile/${userId}/`)
        .then(response => {
          setProfileUser(response.data); // Set the profile user data
          setIsFollowing(response.data.is_following); // Set the follow status
          setFollowRequestStatus(response.data.follow_request_status); // Set the follow request status
          setCanViewProfile(!response.data.is_private || response.data.is_following); // Can view if the profile is not private or the user is following the profile user
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching user profile:', error);
          message.error('Failed to load profile');
          setLoading(false);
        });
    }
  }, [userId, user]);

  // Navigate to followers list
  const handleFollowersClick = () => {
    const targetUserId = userId || user?.id;
    navigate(`/profile/${targetUserId}/followers`);
  };

  // Navigate to following list
  const handleFollowingClick = () => {
    const targetUserId = userId || user?.id;
    navigate(`/profile/${targetUserId}/following`);
  };

  // Fetch notification count (notification cout badge update on private user's profile)
  // const fetchNotificationCount = async () => {
  //   try {
  //     const response = await axiosInstance.get('/users/profile/notifications/?count=true');
  //     setNotificationCount(response.data.count);
  //   } catch (error) {
  //     console.error('Error fetching notification count:', error);
  //   }
  // };

  // useEffect(() => {
  //   if (!userId) { // Only fetch for own profile
  //     fetchNotificationCount();
  //     const interval = setInterval(fetchNotificationCount, 30000); // Refresh every 30 seconds
  //     return () => clearInterval(interval);
  //   }
  // }, [userId]);

  // Update display user to reflect profile user or current user
  useEffect(() => {
    setDisplayUser(profileUser || user);
  }, [profileUser, user]);

  // Add this useEffect to listen for follow request accepted events
  useEffect(() => {
    const handleFollowRequestAccepted = (event) => {
      // Update follower count when we're viewing our own profile
      if (!userId) {
        setDisplayUser(prev => ({
          ...prev,
          followers_count: (prev?.followers_count || 0) + 1
        }));
      }
    };

    window.addEventListener('followRequestAccepted', handleFollowRequestAccepted);
    
    return () => {
      window.removeEventListener('followRequestAccepted', handleFollowRequestAccepted);
    };
  }, [userId]);

  const handleEditProfile = () => {
    navigate('/settings/edit_profile');
  };

  if (!isAuthenticated) {
    return (
      <div className="profile-page">
        <div className="profile-error">
          Please log in to view your profile
        </div>
      </div>
    );
  }

  // If the user is authenticated but the data is still loading, a spinner is displayed.
  if (loading) {
    return (
      <div className="profile-page">
        <div className="profile-loading">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  // If the profile user is private and the current user cannot view the profile, display a message
  if (profileUser?.is_private && !canViewProfile) {
    return (
      <div className="profile-page">
        <div className="profile-header">
          <button 
            onClick={() => navigate('/discover', { 
              state: { previousSearch: location.state?.previousSearch || '' } 
            })} 
            className="back-btn"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="profile-avatar-container">
            <img 
              src={displayUser?.profile_picture || defaultProfilePic}
              alt="Profile"
              className="profile-avatar"
            />
          </div>
          <div className="profile-info">
            <h1 className="display-name">{displayUser?.display_name}</h1>
            <p className="user-id">@{displayUser?.username}</p>
          </div>
          <div className="private-account-message">
            <LockOutlined />
            <p>This account is private.</p>
            <p>Follow this account to see their GoTags.</p>
          </div>
          <div className="profile-actions">
            <Button
              className="follow-btn"
              type={followRequestStatus === 'pending' ? "default" : "primary"}
              onClick={handleFollowToggle}
            >
              {getFollowButtonText()}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  // Format preference
  const formatPreference = (pref) => {
    // Remove array brackets and quotes
    const cleanPref = pref.replace(/[\[\]"]/g, '');
    
    return cleanPref
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // About Section: Location, Language, Food Preferences, Travel Preferences
  const AboutSection = () => {
    return (
      <div className="about-section">
        <div className="about-item">
          <div className="about-row">
            <h3><EnvironmentOutlined /> Location</h3>
            <p>{displayUser?.country || 'Not specified'}</p>
          </div>
        </div>

        <div className="about-item">
          <div className="about-row">
            <h3><GlobalOutlined /> Language</h3>
            <p>{displayUser?.language || 'Not specified'}</p>
          </div>
        </div>

        <div className="about-item">
          <h3>Food Preferences</h3>
          <div className="preferences-tags">
            {Array.isArray(displayUser?.food_preferences) && displayUser.food_preferences.length > 0 ? (
              displayUser.food_preferences.map(pref => (
                <span key={pref} className="preference-tag">
                  {formatPreference(pref)}
                </span>
              ))
            ) : (
              <span className="no-pref">No preferences specified</span>
            )}
          </div>
        </div>

        <div className="about-item">
          <h3>Travel Style</h3>
          <div className="preferences-tags">
            {Array.isArray(displayUser?.travel_preferences) && displayUser.travel_preferences.length > 0 ? (
              displayUser.travel_preferences.map(pref => (
                <span key={pref} className="preference-tag">
                  {formatPreference(pref)}
                </span>
              ))
            ) : (
              <span className="no-pref">No preferences specified</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="profile-page">
      <div className="profile-header">
        {window.location.pathname.includes('/profile/') && (
          <button 
          onClick={handleBackClick}
            className="back-btn"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        <div className="profile-main">
          <div className="profile-avatar-container">
            <img 
              src={displayUser?.profile_picture || defaultProfilePic}
              alt="Profile"
              className="profile-avatar"
            />
          </div>
          <div className="profile-info">
            <h1 className="display-name">{displayUser?.display_name}</h1>
            <p className="user-id">@{displayUser?.username}</p>
            <p className="bio">{displayUser?.bio || 'No bio yet'}</p>
            
            <div className="profile-stats">
              <div className="stat-item" onClick={handleFollowersClick} style={{ cursor: 'pointer' }}>
                <span className="stat-value">{displayUser?.followers_count || 0}</span>
                <span className="stat-label">Followers</span>
              </div>
              <div className="stat-item" onClick={handleFollowingClick} style={{ cursor: 'pointer' }}>
                <span className="stat-value">{displayUser?.following_count || 0}</span>
                <span className="stat-label">Following</span>
              </div>
            </div>

            {userId ? (
              // Only show follow button if the user is viewing another user's profile
              <div className="profile-actions">
                <Button
                  className="follow-btn"
                  type={isFollowing ? "default" : "primary"}
                  onClick={handleFollowToggle}
                >
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </Button>
              </div>
            ) : (
              // Only show edit and settings buttons if the user is viewing their own profile
              <div className="profile-actions">
                <Button 
                  className="edit-profile-btn"
                  icon={<EditOutlined />}
                  onClick={handleEditProfile}
                >
                  Edit Profile
                </Button>
                {/* <Button 
                    className="notifications-btn"
                    onClick={() => navigate('/notifications')}
                >
                    <Badge count={notificationCount} offset={[-5, 5]}>
                        <BellOutlined style={{ fontSize: '20px' }} />
                    </Badge>
                </Button> */}
                <Button 
                  className="settings-btn"
                  icon={<SettingOutlined />}
                  onClick={() => navigate('/settings')}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile-content">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            {
              key: 'go-tags',
              label: 'GoTags',
              children: <GoTagsSection goTags={userGoTags} isOwnProfile={!userId} />,
            },
            {
              key: 'posts',
              label: 'Posts',
              children: <UserPosts userId={userId || user?.id} />,
            },
            {
              key: 'about',
              label: 'About',
              children: <AboutSection />,
            },
            // {
            //   key: 'saved',
            //   label: 'Saved',
            //   children: <div className="saved-grid"></div>,
            // },
          ]}
        />
      </div>
    </div>
  );
}

export default Profile;