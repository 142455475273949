import React, { useState } from 'react';
import DiscoverSearch from './DiscoverSearch';
import PostsGrid from './PostsGrid';
import './Discover.css';

function Discover() {
  const [searchResults, setSearchResults] = useState({ posts: [], users: [] });

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  return (
    <div className="discover-page">
      <div className="discover-top">
        <h1>Explore</h1>
        <DiscoverSearch onSearchResults={handleSearchResults} />
      </div>

      <div className="discover-content">
        <PostsGrid searchResults={searchResults} />
      </div>
    </div>
  );
}

export default Discover;