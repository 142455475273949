import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import langReducer from './langSlice';
import uiReducer from './uiSlice';
import modalReducer from './modalSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lang: langReducer,
    ui: uiReducer,
    modal: modalReducer,
  },
});