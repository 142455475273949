import './Navbar.css';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MoreDropdown from '../MoreDropdown/MoreDropdown';
import defaultProfilePic from '../../assets/images/default_profile_pic.png';
import { faBars, faMap, faCompass, faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/images/logo_cropped.png';

import { setIsMoreDropdownOpen } from '../../store/modalSlice';

const Navbar = ({ setIsAuthModalOpen }) => {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMoreDropdownOpen = useSelector(state => state.modal.isMoreDropdownOpen);
  const toggleDropdown = () => dispatch(setIsMoreDropdownOpen(!isMoreDropdownOpen));

  // Get profile picture from user object
  const getProfilePicture = () => {
    if (user && user.profile_picture) {
      return user.profile_picture;
    }
    return defaultProfilePic;
  };

  // Handle navigation to different pages
  const handleNavigation = (path) => {
    navigate(path);
    dispatch(setIsMoreDropdownOpen(false));
  };  

  return (
    <nav className="navbar">
      <div className="nav-left">
        <img src={logo} alt="Logo" className='navbar-logo' />
        <Link className="nav-btn" to="/">
          <FontAwesomeIcon icon={faMap} /> {t('Map')}
        </Link>
        <Link className="nav-btn" to="/discover">
          <FontAwesomeIcon icon={faCompass} /> {t('Discover')}
        </Link>
        <Link className="nav-btn" to="/chat">
          <FontAwesomeIcon icon={faComments} /> {t('Chat')}
        </Link>
        {isAuthenticated ? (
          <div className="nav-btn" onClick={() => handleNavigation('/profile')}>
            <div className="profile-container">
              <img 
                src={getProfilePicture()} 
                alt={t('Profile')} 
                className="profile-picture" 
              />
              {t('Profile')}
            </div>
          </div>
          ) : (
            <div className="nav-btn" onClick={() => dispatch(setIsAuthModalOpen(true))}>{t('Log In')}</div>
          )}
      </div>
      <div className="nav-bottom">
        <button className="more-btn" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faBars} /> More
        </button>
      </div>
      
      {isMoreDropdownOpen && <MoreDropdown onNavigate={handleNavigation} isAuthenticated={isAuthenticated}/>}
    </nav>
  );
}

export default Navbar;