import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './InfoWindow.css';
import '../../styles/shared/GoTag.css';

const InfoWindow = ({ poi, onClose, onAddLabel, onDeleteLabel, currentUser }) => {
  const [newLabel, setNewLabel] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [showAllLabels, setShowAllLabels] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newLabel.trim()) {
      onAddLabel(newLabel, poi);
      setNewLabel('');
      setShowInput(false);
    }
  };

  // Sort labels by count
  const sortedLabels = poi.labels && poi.labels.length > 0
    ? [...poi.labels].sort((a, b) => b.count - a.count)
    : [];

  // Display labels
  const displayedLabels = showAllLabels 
    ? sortedLabels 
    : sortedLabels.slice(0, 5);

  return (
    <div className="custom-info-window">
      <h4 className="poi-title">{poi.name}</h4>
      {/* <p className="poi-type">Type: {poi.type}</p> */}
      {poi.photos && poi.photos.length > 0 && (
        <div className="poi-photos">
          <img src={poi.photos[0]} alt={poi.name} className="poi-photo" />
        </div>
      )}
      <p className="poi-address">Address: {poi.address}</p>
      {poi.tel && <p className="poi-tel">Tel: {poi.tel}</p>}
      {poi.website && (
        <p className="poi-website">
          Website: <a href={poi.website} target="_blank" rel="noopener noreferrer">{poi.website}</a>
        </p>
      )}
      <p className="poi-labels">
        GoPills:
        {displayedLabels.length > 0 ? (
          <>
            {displayedLabels.map((label, index) => (
              <span key={index} className="label-pill">
                {label.label.name}
                {currentUser && currentUser.id === label.label.created_by && (
                  <button 
                    className="delete-label-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteLabel(label.label.id);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
                <span className="label-count">{label.count}</span>
              </span>
            ))}
            {sortedLabels.length > 5 && (
              <button 
                onClick={() => setShowAllLabels(!showAllLabels)} 
                className="view-more-btn"
              >
                {showAllLabels ? 'Show Less' : `View All (${sortedLabels.length})`}
              </button>
            )}
          </>
        ) : (
          <span> No GoPills added yet.</span>
        )}
        <form onSubmit={handleSubmit} className="label-form">
          <input
            type="text"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
            placeholder="Add a GoPill"
            className="label-input"
          />
        </form>
      </p>
      <div className="button-container">
      {showInput ? (
          <form onSubmit={handleSubmit} className="label-form">
            <input
              type="text"
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
              placeholder="Enter label name"
              className="label-input"
            />
            <button type="submit" className="info-btn">Add</button>
            <button 
              type="button" 
              onClick={() => setShowInput(false)} 
              className="info-btn close-btn"
            >
              Cancel
            </button>
          </form>
        ) : (
          <>
            <button onClick={() => setShowInput(true)} className="info-btn">Add label</button>
            <button onClick={onClose} className="info-btn close-btn">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoWindow;