import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';

const SearchBar = ({ onSearch, onInput, onClear,placeholder, searchQuery, setSearchQuery, hasResults, hasQuery }) => {
  const handleSearch = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchQuery);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (onInput) {
      onInput(value);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className="search-overlay">
      <form onSubmit={handleSearch} className={`search-container ${hasResults ? 'has-results' : ''}`}>
        <input
          type="text"
          className="search-input"
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleInput}
        />
        {hasQuery ? (
          <button type="button" className="search-btn" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        ) : (
          <button type="submit" className="search-btn">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        )}
      </form>
    </div>
  );
};

export default SearchBar;