import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Carousel, Input, Button, message, Dropdown } from 'antd';
import { HeartOutlined, HeartFilled, CommentOutlined, MoreOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../api/axiosInstance';
import './PostDetail.css';

const PostDetail = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    fetchPost();
  }, [postId]);

  const fetchPost = async () => {
    try {
      const response = await axiosInstance.get(`/posts/${postId}/`);
      setPost(response.data);
      setLiked(response.data.is_liked);
      console.log('Post data:', response.data);
    } catch (error) {
      console.error('Error fetching post:', error);
      message.error('Failed to load post');
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    try {
      await axiosInstance.post(`/posts/${postId}/like/`);
      setLiked(!liked);
      setPost(prev => ({
        ...prev,
        likes_count: liked ? prev.likes_count - 1 : prev.likes_count + 1
      }));
    } catch (error) {
      message.error('Failed to like post');
    }
  };

  const handleComment = async () => {
    try {
      await axiosInstance.post(`/posts/${postId}/comment/`, { text: comment });
      console.log('PostDetail Comment:', comment);
      setComment('');
      fetchPost(); // Refresh to get new comments
    } catch (error) {
      message.error('Failed to post comment');
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDeletePost = async () => {
    try {
      await axiosInstance.delete(`/posts/${postId}/`);
      navigate(-1);
    } catch (error) {
      message.error('Failed to delete post');
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div className="detail-post-detail">
      <div className="detail-post-header">
        <button 
          onClick={handleBackClick}
          className="back-btn"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>

      <div className="detail-post-images">
        <Carousel>
          {post.images?.map((image, index) => (
            <div key={index}>
              <img src={image.image} alt={`Post ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>

      <div className="detail-info">
        <div className="detail-header">
          <span className="username">{post.user}</span>
        </div>

        {post.posts_images && post.posts_images.length > 0 && (
          <div className="detail-images">
            {post.posts_images.map((image, index) => (
              <img src={image.image} alt={`Post ${index + 1}`} key={index} />
            ))}
          </div>
        )}

        <div className="detail-caption">{post.caption}</div>
        <div className="detail-content">{post.content}</div>

        <div className="detail-actions">
          <Button 
            icon={liked ? <HeartFilled /> : <HeartOutlined />} 
            onClick={handleLike}
          >
            {post.likes_count} Likes
          </Button>
          <Button icon={<CommentOutlined />}>
            {post.posts_comments?.length} Comments
          </Button>
        </div>

        <div className="detail-comments">
          {post.posts_comments?.map(comment => (
            <div key={comment.id} className="detail-comment">
              <span className="detail-comment-user">{comment.user}: </span>
              <span className="detail-comment-text">{comment.text}</span>
            </div>
          ))}
        </div>

        <div className="detail-comment-input">
          <Input.TextArea 
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
            rows={2}
          />
          <Button type="primary" onClick={handleComment}>
            Post Comment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
