// Page 1: MAIN TOUR PAGE (displays the tour categories)

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { Link } from 'react-router-dom';
import './Tours.css'; // Import the CSS file

const Tours = () => {
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get('/tours/categories/');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get('/tours/locations/');
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
    fetchLocations();
  }, []);

  return (
    <div className="tours-container">
      <h1>Tours & Experiences</h1>
      <h2>Locations</h2>
      <div className="tours-location">
        {locations.length > 0 ? (
          locations.map(location => (
            <div key={location.id} className="location-card">
              <Link to={`/location/${location.id}`}>
                <h2>{location.name}</h2>
              </Link>
            </div>
          ))
        ) : (
          <p>No tours by locations available.</p>
        )}
      </div>

      <h2>Categories</h2>
      <div className="tours-categories">
        {categories.length > 0 ? (
          categories.map(category => (
            <div key={category.id} className="category-card">
              <Link to={`/category/${category.id}`}>
                <h2>{category.name}</h2>
              </Link>
            </div>
          ))
        ) : (
          <p>No tours by categories available.</p>
        )}
      </div>
    </div>
  );
};

export default Tours;