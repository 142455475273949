import React, { useState, useEffect, useRef, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Masonry from 'react-masonry-css';
import { Select, Button } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './PostsGrid.css';
import axiosInstance from '../../api/axiosInstance';

const PostsGrid = ({ searchResults }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.auth);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState('recent');
  const pageRef = useRef(1);
  const observer = useRef();
  const loadingRef = useRef(null);
  const [initialized, setInitialized] = useState(false);

  const breakpointColumns = {
    default: 4,
    1600: 3,
    1200: 3,
    992: 2,
    768: 2,
    576: 1
  };

  useEffect(() => {
    if (searchResults?.posts?.length > 0) {
      setPosts(searchResults.posts);
      setHasMore(false);
    }
  }, [searchResults]);

  const fetchPosts = useCallback(async (reset = false) => {
    console.log("fetching posts");
    if (loading || (!hasMore && !reset)) return;
    
    try {
      setLoading(true);
      const page = reset ? 1 : pageRef.current;

      const response = await axiosInstance.get(`/posts/?page=${page}&page_size=10&sort=${sortBy}`);
      const { results, next } = response.data;
      
      setPosts(prevPosts => {
        if (reset || prevPosts.length === 0) return results;
        
        const newPosts = [...prevPosts];
        results.forEach(post => {
          if (!newPosts.some(p => p.id === post.id)) {
            newPosts.push(post);
          }
        });
        return newPosts;
      });
      
      setHasMore(!!next);
      pageRef.current = reset ? 2 : pageRef.current + 1;
      if (!initialized) setInitialized(true);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [sortBy, loading, hasMore, initialized]);

  useEffect(() => {
    console.log("posts updated:", posts);
  }, [posts]);

  // observer cleanup
  useEffect(() => {
    const currentObserver = observer.current;
    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [hasMore]);

  // Initial load effect
  useEffect(() => {
    if (!initialized) {
      pageRef.current = 1;
      fetchPosts(true);
    }
  }, [initialized]);

  // Sort change effect
  useEffect(() => {
    if (initialized) {  // Only respond to sort changes after initial load
      pageRef.current = 1;
      fetchPosts(true);
    }
  }, [sortBy]);

  // Intersection observer effect
  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading && initialized) {
          fetchPosts();
        }
      }, {
        root: null,
        rootMargin: '20px',
        threshold: 1.0
      });
    }

    const trigger = document.querySelector('.scroll-trigger');
    if (trigger) {
      observer.current.observe(trigger);
    }

    return () => {
      if (trigger) {
        observer.current?.unobserve(trigger);
      }
    };
  }, [hasMore, loading, initialized]);

  const handlePostClick = (postId) => {
    navigate(`/posts/${postId}`);
  };

  return (
    <div className="posts-grid-container">
      <div className="posts-grid-header">
        {isAuthenticated && (
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={() => navigate('/create-post')}
            className="posts-grid-create-post-button"
          >
            Create Post
          </Button>
        )}
      </div>

      <Masonry
        breakpointCols={breakpointColumns}
        className="posts-grid"
        columnClassName="posts-grid-column"
      >
        {posts.map((post) => (
          <div 
            key={post.id} 
            className="post"
            onClick={() => handlePostClick(post.id)}
            style={{ cursor: 'pointer' }}
          >
              <div className="post-header">
                <span className="post-author">{post.user}</span>
              </div>
            {post.posts_images && post.posts_images.length > 0 && (
              <div className="post-images">
                <img 
                  src={post.posts_images[0].image} 
                  alt={`Post by ${post.user}`}
                  onError={(e) => console.error('Image failed to load:', e)}
                />
              </div>
            )}
            <div className="post-content">
              <div className="post-caption">{post.caption}</div>
              <div className="post-footer">
                <span className="post-date">
                  {new Date(post.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </Masonry>

      {loading && (
        <div className="loading-container">
          {[1, 2, 3, 4].map((n) => (
            <Skeleton key={n} height={300} className="post-skeleton" />
          ))}
        </div>
      )}
      
      <div className="scroll-trigger" />
      {hasMore && <div ref={loadingRef} style={{ height: '20px' }} />}
    </div>
  );
}

export default PostsGrid;