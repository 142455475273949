import React, { useState } from "react";
import axiosInstance from '../../api/axiosInstance';
import './CreatePost.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CreatePost = () => {
  const navigate = useNavigate();
  const [caption, setCaption] = useState("");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length > 10) {
      alert("You can only upload up to 10 images per post");
      e.target.value = ''; // Reset file input
      return;
    }
    
    setImages(files);
    
    // Create preview URLs for all selected images
    const previews = [];
    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previews.push(reader.result);
        if (previews.length === files.length) {
          setImagePreviews(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("caption", caption);
    formData.append("content", content);
    images.forEach(image => {
      formData.append("images", image);
    });

    try {
      const response = await axiosInstance.post("/posts/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Post created:', response.data);
      // if the post is created successfully, navigate to the profile page if in profile page; if in discover page, navigate to the discover page
      if (window.location.pathname === '/profile') {
        navigate('/profile');
      } else {
        navigate('/discover');
      }
    } catch (error) {
      console.error('Error response:', error.response?.data);
      alert(error.response?.data?.detail || "Error creating post");
    }
  };

  return (
    <div className="create-post-page">
      <button 
        onClick={handleBackClick}
        className="back-btn"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="create-post-container">
        <form onSubmit={handleSubmit} className="create-post-form">
          <h2>Create a New Post</h2>
          
          <div className="create-post-form-group">
            <label>Caption</label>
            <input
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Write a caption for your post..."
              required
            />
          </div>

          <div className="create-post-form-group">
            <label>Content</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Share your thoughts..."
              required
            />
          </div>

          <div className="create-post-form-group">
            <label>Images</label>
            <input 
              type="file" 
              onChange={handleFileChange}
              accept="image/*"
              multiple
              required
            />
            <div className="image-previews">
              {imagePreviews.map((preview, index) => (
                <img 
                  key={index}
                  src={preview} 
                  alt={`Preview ${index + 1}`} 
                  className="preview-image"
                />
              ))}
            </div>
          </div>

          <button type="submit" className="submit-button">
            Create Post
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePost;
