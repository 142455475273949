import React, { useState, useEffect } from 'react';
import { Input, List, Avatar, Spin, message } from 'antd';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../api/axiosInstance';
import { useSelector } from 'react-redux';
import './FollowersFollowingList.css';

function FollowersFollowingList({ type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();
  const { user } = useSelector(state => state.auth);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // If userId is not provided, use the current user's ID
        const targetUserId = userId || user?.id;
        const endpoint = `/users/profile/${targetUserId}/${type}/`;
        const response = await axiosInstance.get(endpoint);
        setUsers(response.data);
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
        message.error(`Failed to load ${type}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [userId, type, user]);

  // Navigate to user profile
  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`, {
      state: { 
        from: type,  // 'followers' or 'following'
        previousPath: location.pathname // Store the current path
      }
    });
  };

  // Filter users by search query (username or display name)
  const filteredUsers = users.filter(user =>
    user.display_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="followers-following-list">
      <div className="list-header">
        <button onClick={() => navigate(-1)} className="back-btn">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h1 className="list-title">{type.charAt(0).toUpperCase() + type.slice(1)}</h1>
      </div>

      <Input
        placeholder={`Search ${type} by name or username...`}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        className="followers-following-search-input"
        suffix={<FontAwesomeIcon icon={faSearch} style={{ color: '#b22222' }} />}
      />
      
      {loading ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={filteredUsers}
          renderItem={user => (
            <List.Item 
                className="user-list-item"
                onClick={() => handleUserClick(user.id)}
                style={{ cursor: 'pointer' }}
            >
              <List.Item.Meta
                avatar={<Avatar src={user.profile_picture} />}
                title={user.display_name}
                description={`@${user.username}`}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

export default FollowersFollowingList;