import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './MobileInfoWindow.css';
import { message } from 'antd';
import Select from 'react-select';

const MobileInfoWindow = ({ poi, onClose, onAddLabel, onPhotoClick, onDeleteLabel, publicGoTags }) => {
  console.log('Public GoTags in MobileInfoWindow:', publicGoTags);

  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [newLabel, setNewLabel] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [activeTab, setActiveTab] = useState('photos');
  const [showDirectionsModal, setShowDirectionsModal] = useState(false);
  const windowRef = useRef(null);
  const [showAllLabels, setShowAllLabels] = useState(false);
  const [showAllPublicLabels, setShowAllPublicLabels] = useState(false);
  const [showAllPrivateLabels, setShowAllPrivateLabels] = useState(false);
  const currentUser = useSelector(state => state.auth.user);

  // Handle touch start
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
    setCurrentY(0);
  };
  
  // Handle touch move
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const deltaY = e.touches[0].clientY - startY;
    const scrollableContent = windowRef.current.querySelector('.scrollable-content');
    const isScrolledToTop = scrollableContent.scrollTop === 0;
    
    if (isFullscreenMode) {
      // In fullscreen, only allow dragging down
      if (deltaY > 0 && isScrolledToTop) {
        setCurrentY(deltaY);
        e.preventDefault();
      }
    } else {
      // In halfscreen, allow dragging up to enter fullscreen
      if ((deltaY < 0) || (deltaY > 0 && isScrolledToTop)) {
        setCurrentY(deltaY);
        e.preventDefault();
      }
    }
  };
  
  // Handle touch end
  const handleTouchEnd = () => {
    if (!isDragging) return;
    
    const threshold = 100;
    if (isFullscreenMode) {
      // Exit fullscreen if dragged down enough
      if (currentY > threshold) {
        setIsFullscreenMode(false);
      }
    } else {
      // Enter fullscreen if dragged up enough
      if (currentY < -threshold) {
        setIsFullscreenMode(true);
      }
      // Close infowindow if dragged down enough in halfscreen mode
      else if (currentY > threshold) {
        onClose();
      }
    }
    
    setCurrentY(0);
    setIsDragging(false);
  };

  // Handle label submit
  const handleLabelSubmit = async (e) => {
    e.preventDefault();
    if (newLabel.trim()) {
      // Check if the label already exists
      const isDuplicate = [...myPublicLabels, ...myPrivateLabels].some(
        label => label.label.name.toLowerCase() === newLabel.trim().toLowerCase()
      );
      
      if (isDuplicate) {
        message.error('You already have a GoTag with this name');
        return;
      }

      // Add the label to the POI
      const addSuccess = await onAddLabel(newLabel, poi, isPublic); // Add the label to the POI (defined in MapContainer.js)
      if (addSuccess) {
        setNewLabel(''); // Clear the input field
      }
    }
  };

  // Handle Gaode map POI click
  const handleGaodeMapClick = (e) => {
    e.preventDefault();
    
    // Create both URIs
    const appUri = `amapuri://route/to/poi?poiid=${poi.id}&dev=0`;
    const webUri = `https://uri.amap.com/marker?position=${poi.location.lng},${poi.location.lat}&name=${encodeURIComponent(poi.name)}`;
    
    // Create an invisible iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Set a timeout to redirect to web version if app doesn't open
    const timeout = setTimeout(() => {
      document.body.removeChild(iframe);
      window.location.href = webUri;
    }, 2000);

    // Try to open the app
    iframe.src = appUri;

    // Clean up if app opens successfully
    iframe.onload = () => {
      clearTimeout(timeout);
      document.body.removeChild(iframe);
    };
  };

  const handlePhotoClick = (index) => {
    onPhotoClick(poi.photos, index);
  };

  // Sort labels by count
  const sortedLabels = poi.labels && poi.labels.length > 0
    ? [...poi.labels].sort((a, b) => b.count - a.count)
    : [];

  // Filter general GoTags by visibility
  const publicLabels = sortedLabels.filter(label => label.label.is_public);
  const myLabels = currentUser ? sortedLabels.filter(label => label.label.created_by === currentUser.id) : [];

  // Filter MyGoTags by visibility
  const myPublicLabels = currentUser ? myLabels.filter(label => label.label.is_public) : [];
  const myPrivateLabels = currentUser ? myLabels.filter(label => !label.label.is_public) : [];

  // Display labels
  const displayedPublicLabels = showAllLabels 
  ? publicLabels 
  : publicLabels.slice(0, 5);

  const displayedMyPublicLabels = showAllPublicLabels 
  ? myPublicLabels 
  : myPublicLabels.slice(0, 3);

  const displayedMyPrivateLabels = showAllPrivateLabels 
  ? myPrivateLabels 
  : myPrivateLabels.slice(0, 3);

  const handleSelectChange = (selectedOption) => {
    setNewLabel(selectedOption ? selectedOption.value : '');
  };

  return (
    <div 
      ref={windowRef}
      className={`mobile-info-window ${isFullscreenMode ? 'fullscreen' : 'halfscreen'}`}
      style={{
        transform: `translateY(${currentY}px)`,
        transition: isDragging ? 'none' : 'transform 0.3s, height 0.3s'
      }}
    >
      {/* Drag indicator */}
      <div 
        className="header"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="drag-indicator"></div>
      </div>
      
      {/* Scrollable content */}
      <div className="scrollable-content">
        {/* POI header */}
        <div className="poi-header">
          <div className="poi-main-info">
            <h4 className="poi-title">{poi.name}</h4>
            <p className="poi-type">{poi.type}</p>
          </div>
          <div className="action-buttons">
            {poi.tel && (
              <a href={`tel:${poi.tel}`} className="action-btn call-btn">
                <i className="fas fa-phone"></i>
              </a>
            )}
            {/* Directions button */}
            <button onClick={() => setShowDirectionsModal(true)} className="action-btn directions-btn">
              <i className="fas fa-directions"></i>
            </button>
          </div>
        </div>

        {/* GoTags */}
        <div className="poi-labels">
          <h5>GoTags:</h5>
          <div className="label-groups-gotag">
            {displayedPublicLabels.length > 0 ? (
              <>
                {displayedPublicLabels.map((label, index) => (
                  <span 
                    key={index} 
                    className="label-pill public"
                  >
                    {label.label.name}
                    <span className="label-count">{label.count}</span>
                  </span>
                ))}
                {publicLabels.length > 5 && (
                  <button 
                    onClick={() => setShowAllLabels(!showAllLabels)} 
                    className="view-more-btn"
                  >
                    {showAllLabels ? 'Show Less' : `View All (${publicLabels.length})`}
                  </button>
                )}
              </>
            ) : (
              <span className="no-labels">No public GoTags added yet.</span>
            )}
          </div>

          <h5>My GoTags:</h5>
          <div className="label-groups">
            {myLabels.length > 0 ? (
              <>
                {/* public my gotags */}
                <div className="label-groups-public">
                  <strong>Public:</strong>
                  {displayedMyPublicLabels.map((label, index) => (
                    <span 
                      key={`public-${index}`} 
                      className="label-pill public"
                    >
                      {label.label.name}
                      <button 
                        className="delete-label-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteLabel(label.label.id);
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </span>
                  ))}
                  {myPublicLabels.length > 3 && (
                    <button 
                      onClick={() => setShowAllPublicLabels(!showAllPublicLabels)}
                      className="view-more-btn"
                    >
                      {showAllPublicLabels ? 'Show Less' : 'View All'}
                    </button>
                  )}
                </div>

                {/* private my gotags */}
                <div className="label-groups-private">
                  <strong>Private:</strong>
                  {displayedMyPrivateLabels.map((label, index) => (
                    <span 
                      key={`private-${index}`} 
                      className="label-pill private"
                    >
                      {label.label.name}
                      <button 
                        className="delete-label-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteLabel(label.label.id);
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </span>
                  ))}
                  {myPrivateLabels.length > 3 && (
                    <button 
                      onClick={() => setShowAllPrivateLabels(!showAllPrivateLabels)}
                      className="view-more-btn"
                    >
                      {showAllPrivateLabels ? 'Show Less' : 'View All'}
                    </button>
                  )}
                </div>
              </>
            ) : (
              <span className="no-labels">Add your own GoTag!</span>
            )}
          </div>

          {/* Add new GoTag */}
          <form onSubmit={handleLabelSubmit} className="label-form">
            <div className="label-input-container">
              {isPublic ? (
                <Select
                  options={publicGoTags.map(tag => ({ value: tag, label: tag }))}
                  onChange={handleSelectChange}
                  placeholder="Add a public GoTag"
                />
              ) : (
                <input
                  type="text"
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                  placeholder="Add a private GoTag"
                  className="label-input"
                />
              )}
              <select
                value={isPublic ? 'public' : 'private'}
                onChange={(e) => setIsPublic(e.target.value === 'public')}
                className="visibility-selector"
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
              <button
                type="submit"
                className={`add-label-btn ${!newLabel.trim() ? 'disabled' : ''}`}
                disabled={!newLabel.trim()}
              >
                Add
              </button>
            </div>
          </form>
        </div>

        <div className="tabs">
        <button 
            className={`tab-btn ${activeTab === 'photos' ? 'active' : ''}`}
            onClick={() => setActiveTab('photos')}
          >
            Photos
          </button>
          <button 
            className={`tab-btn ${activeTab === 'about' ? 'active' : ''}`}
            onClick={() => setActiveTab('about')}
          >
            About
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'about' && (
            <div className="about-tab">
              <p className="poi-address">Address: {poi.address}</p>
              {poi.tel && <p className="poi-tel">Tel: {poi.tel}</p>}
              {poi.website && (
                <p className="poi-website">
                  Website: <a href={poi.website} target="_blank" rel="noopener noreferrer">{poi.website}</a>
                </p>
              )}
            </div>
          )}
          
          {activeTab === 'photos' && poi.photos && poi.photos.length > 0 && (
            <div className="photos-tab">
              <div className="poi-photos-container">
                <div className="poi-photos-scroll">
                  {poi.photos.map((photo, index) => (
                    <div 
                      key={index} 
                      className="poi-photo-wrapper"
                      onClick={() => handlePhotoClick(index)}
                    >
                      <img src={photo} alt={`${poi.name} ${index + 1}`} className="poi-photo" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showDirectionsModal && (
        <div className="directions-modal" onClick={() => setShowDirectionsModal(false)}>
          <button className="close-fullscreen-btn" onClick={() => setShowDirectionsModal(false)}>
            <i className="fas fa-times"></i>
          </button>
          <div className="directions-content" onClick={e => e.stopPropagation()}>
            <div className="maps-list">
              <a href={`https://maps.google.com/?q=${poi.address}`} target="_blank" rel="noopener noreferrer" className="map-option">
                <i className="fab fa-google"></i> Google Maps
              </a>
              <a href={`https://maps.apple.com/?q=${poi.address}`} target="_blank" rel="noopener noreferrer" className="map-option">
                <i className="fab fa-apple"></i> Apple Maps
              </a>
              <a
                href="#"
                onClick={handleGaodeMapClick} 
                className="map-option"
              >
                <i className="fas fa-map"></i> Gaode Maps
              </a>
              <a href={`https://map.baidu.com/search/${poi.address}`} target="_blank" rel="noopener noreferrer" className="map-option">
                <i className="fas fa-map"></i> Baidu Maps
              </a>
              <a href={`https://map.qq.com/?type=marker&isopeninfowin=1&marker=coord:${poi.location.lat},${poi.location.lng}`} target="_blank" rel="noopener noreferrer" className="map-option">
                <i className="fas fa-map"></i> Tencent Maps
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileInfoWindow; 