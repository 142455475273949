import { useCallback } from 'react';
import { fetchLabels } from '../utils/labelHelpers';
export function useEventListeners(stateManager, AMapAPI, placeSearchRef) {
  
  // Handle hotspot click (when user clicks on a POI in the map)
  const handleHotspotClick = useCallback((e) => {
    if (e && AMapAPI && placeSearchRef.current) {
        placeSearchRef.current.getDetails(e.id, async (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              console.log("search complete");
                const poiDetails = result.poiList.pois[0];
                if (poiDetails) {
                    const poi = {
                        id: poiDetails.id,
                        name: poiDetails.name,
                        type: poiDetails.type,
                        location: {
                        lng: poiDetails.location.lng,
                            lat: poiDetails.location.lat
                        },
                        address: poiDetails.address,
                        adcode: poiDetails.adcode,
                        adname: poiDetails.adname,
                        citycode: poiDetails.citycode,
                        cityname: poiDetails.cityname,
                        pcode: poiDetails.pcode,
                        pname: poiDetails.pname,
                        tel: poiDetails.tel || '',
                        website: poiDetails.website || '',
                        email: poiDetails.email || '',
                        // photos: poiDetails.photos.map(photo => photo.url),
                        // photos: Array.isArray(poiDetails.photos) ? poiDetails.photos.map(photo => photo.url) : [],
                        photos: Array.isArray(poiDetails.photos) 
                            ? poiDetails.photos.map(photo => photo.url.replace('http://', 'https://')) 
                            : [],
                        distance: poiDetails.distance,
                        postcode: poiDetails.postcode || '',
                        shopinfo: poiDetails.shopinfo,
                        indoor_map: poiDetails.indoor_map,
                        discount: poiDetails.discount,
                        groupbuy: poiDetails.groupbuy,
                        entr_location: poiDetails.entr_location,
                        exit_location: poiDetails.exit_location,
                    }
                    const labels = await fetchLabels(poi.id);
                    await stateManager.setActivePOI({
                        ...poi,
                        labels: labels
                    });
                } else {
                    console.error('Failed to get POI details:', result.info);
                }
            } else {
                console.error('Search failed:', result.info);
            }
        });
    }
  }, [stateManager, AMapAPI, placeSearchRef]);

  // handle map click event
  const handleMapClick = useCallback((e) => {
    const {lng, lat} = e.lnglat;
    placeSearchRef.current.searchNearBy('', [lng, lat], 300, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
        const pois = result.poiList.pois; // Get the list of points of interest
        if (pois.length > 0) {
            // Process the first POI or handle multiple POIs as needed
            const firstPOI = pois[0];
            stateManager.setActivePOI({
                id: firstPOI.id,
                name: firstPOI.name,
                location: {
                    lng: firstPOI.location.lng,
                    lat: firstPOI.location.lat
                },
                address: firstPOI.address,
                // Add other properties as needed
            });
        } else {
            console.error('No POIs found for the search term.');
        }
    } else {
        // Handle search failure
        console.error('Search failed:', result.info);
        }
    })

}, [stateManager, placeSearchRef]);

  const setupEventListeners = useCallback((mapInstance, currentLanguage) => {
    mapInstance.off('hotspotclick');
    mapInstance.off('click');

    if (currentLanguage === 'zh_cn') {
      // Add class to hotspots
      const hotspots = document.getElementsByClassName('amap-marker');
      Array.from(hotspots).forEach(hotspot => {
        hotspot.classList.add('map-hotspot');
      });
    
      mapInstance.on('hotspotclick', handleHotspotClick);
    } else {
      mapInstance.on('click', handleMapClick);
    }
  }, [handleHotspotClick, handleMapClick]);

  return { setupEventListeners };
}

  
