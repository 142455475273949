// Page 2: displays all the tours depending on category/location

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { useParams } from 'react-router-dom';
import './CategoryTours.css';
import { Link } from 'react-router-dom';

const CategoryTours = () => {
  const { id } = useParams();
  const [tours, setTours] = useState([]);

  // Fetch tours based on the category or location ID
  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axiosInstance.get(`/tours/tours/?location=${id}`);
        console.log('Fetched tours:', response.data);
        setTours(response.data);
      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    };
    fetchTours();
  }, [id]);

  return (
    <div className="category-tours-container">
      <h1>Tours for this category/location</h1>
      <div className="tours-list">
        {Array.isArray(tours) && tours.length > 0 ? (
          tours.map(tour => (
            <div key={tour.id} className="tour-card">
              <img src={tour.image_url} alt={tour.title} className="tour-image" />
              <p className="tour-category-location">{tour.category} . {tour.location.name}</p>
              <h2>
                <Link to={`/tour/${tour.id}`}>{tour.title}</Link>
              </h2>
              <p className="tour-rating-bookings">
                {tour.average_rating} ({tour.number_of_bookings} bookings)
              </p>
              <p className="tour-price">{tour.price}</p>
            </div>
          ))
        ) : (
          <p>No tours available.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryTours;
