import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { setLanguage } from '../../store/langSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditProfile from '../../components/EditProfile/EditProfile';
import axiosInstance from '../../api/axiosInstance';
import { updateUser } from '../../store/authSlice';
import PasswordManagement from '../../components/PasswordManagement/PasswordManagement';

import { 
  faUser, 
  faLock,  
  faLanguage, 
  faEye
} from '@fortawesome/free-solid-svg-icons';
import './Settings.css';

function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // navigate to different pages
  const location = useLocation(); // returns current URL location
  const currentPath = location.pathname.split('/')[2] || 'edit_profile'; // gets current URL path
  const { user } = useSelector(state => state.auth); // get user data from Redux store
  const language = useSelector(state => state.lang.language); // get language from Redux store
  const { t, i18n } = useTranslation(); // internationalization

  const changeLanguage = (newLanguage) => {
    dispatch(setLanguage(newLanguage));
    i18n.changeLanguage(newLanguage);
  };

  const [isPrivate, setIsPrivate] = useState(false); // state for privacy toggle

  const togglePrivacy = async () => {
    try {
      // Toggle the local state
      const newPrivacyStatus = !isPrivate;
      setIsPrivate(newPrivacyStatus); // true if private, false if public

      console.log('newPrivacyStatus: ', newPrivacyStatus);
  
      // Call API to update privacy setting
      const response = await axiosInstance.patch('/users/profile/account_privacy/', { is_private: newPrivacyStatus });

      console.log(response);
  
      // Optionally, update the user data in the Redux store
      dispatch(updateUser(response.data));
  
      // Provide feedback to the user
      console.log('Privacy status updated successfully');
    } catch (error) {
      console.error('Failed to update privacy status:', error);
      // Optionally, revert the local state if the API call fails
      setIsPrivate(!isPrivate);
    }
  };

  // Menu items for sidebar navigation
  const menuGroups = [
    {
      title: t('Account Settings'),
      items: [
        { id: 'edit_profile', label: t('Edit Profile'), icon: faUser },
        { id: 'password_management', label: t('Password Management'), icon: faLock },
        { id: 'language', label: t('Language'), icon: faLanguage },
      ]
    },
    // {
    //   title: t('Privacy and Security'),
    //   items: [
    //     { id: 'account_privacy', label: t('Account Privacy'), icon: faEye },
    //   ]
    // }
  ];

  // Navigate to the selected menu item
  const handleMenuClick = (itemId) => {
    navigate(`/settings/${itemId}`);
  };

  return (
    <div className="settings-container">
      <div className="settings-sidebar">
        {menuGroups.map((group, index) => (
          <div key={index} className="menu-group">
            <h3 className="menu-group-title">{group.title}</h3>
            <ul className="menu-items">
              {group.items.map(item => (
                <li
                  key={item.id}
                  className={`menu-item ${currentPath === item.id ? 'active' : ''}`}
                  onClick={() => handleMenuClick(item.id)}
                >
                  <FontAwesomeIcon icon={item.icon} className="menu-icon" />
                  <span>{item.label}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="settings-content">
        {currentPath === 'edit_profile' && (
          <div>
            <h2>{t('Edit Profile')}</h2>
            <EditProfile 
              dispatch={dispatch}
              initialValues={{
                username: user?.username,
                display_name: user?.display_name,
                bio: user?.bio,
                country: user?.country,
                language: user?.language,
                profile_picture: user?.profile_picture,
                food_preferences: user?.food_preferences || [],
                travel_preferences: user?.travel_preferences || [],
              }}
            />
          </div>
        )}

        {currentPath === 'password_management' && (
          <div>
            <h2>{t('Password Management')}</h2>
            <PasswordManagement />
          </div>
        )}

        {currentPath === 'language' && (
          <div>
            <h2>{t('Language Preferences')}</h2>
            <p>{t('See buttons, titles, and other texts on ChinaGO in your preferred language.')}</p>
            <select
              className="select-language"
              value={language}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="en">{t('English')}</option>
              <option value="zh">{t('Chinese')}</option>
              {/* Add more language options as needed */}
            </select>
          </div>
        )}

        {currentPath === 'account_privacy' && (
          <div>
            <h2>{t('Account Privacy')}</h2>
            <div className="privacy-setting">
              <h3>{t('Private account')}</h3>
              <p>{t('When your account is public, your profile and posts can be seen by anyone, on or off ChinaGO, even if they don\'t have an ChinaGO account. When your account is private, only the followers you approve can see what you share, including your photos or videos on hashtag and location pages, and your followers and following lists. Certain information on your profile, like your profile picture and username, is visible to everyone on and off ChinaGO.')}</p>
              <label className="switch">
                <input type="checkbox" checked={isPrivate} onChange={togglePrivacy} />
                <span className="slider round"></span>
              </label>
            </div>  
          </div>
        )}
        
      </div>
    </div>
  );
}

export default Settings;