// src/components/Home.js
import React from "react";
import MapContainer from "../../components/MapContainer/MapContainer";

// Define the Home component
export default function Home() {
  return (
    <div>
      <MapContainer />
    </div>
  );
}
