export const fetchPOIDetails = async (id, placeSearchRef) => {
    return new Promise((resolve, reject) => {
      if (!id || !placeSearchRef.current) {
        reject(new Error('Invalid POI ID or placeSearch not initialized'));
        return;
      }

      placeSearchRef.current.getDetails(id, async (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          const poiDetails = result.poiList.pois[0]; // POI details
          if (poiDetails) {
            const fullPoi = {
              id: poiDetails.id,
              name: poiDetails.name,
              type: poiDetails.type,
              location: {
                lng: poiDetails.location.lng,
                lat: poiDetails.location.lat
              },
              address: poiDetails.address,
              adcode: poiDetails.adcode,
              adname: poiDetails.adname,
              citycode: poiDetails.citycode,
              cityname: poiDetails.cityname,
              pcode: poiDetails.pcode,
              pname: poiDetails.pname,
              tel: poiDetails.tel || '',
              website: poiDetails.website || '',
              email: poiDetails.email || '',
              photos: Array.isArray(poiDetails.photos) 
                ? poiDetails.photos.map(photo => photo.url.replace('http://', 'https://'))
                : [],
              distance: poiDetails.distance,
              postcode: poiDetails.postcode || '',
              shopinfo: poiDetails.shopinfo,
              indoor_map: poiDetails.indoor_map,
              discount: poiDetails.discount,
              groupbuy: poiDetails.groupbuy,
              entr_location: poiDetails.entr_location,
              exit_location: poiDetails.exit_location,
            };
            resolve(fullPoi); // Return full POI details
          } else {
            reject(new Error('Failed to get POI details'));
          }
        } else {
          reject(new Error(`Search failed: ${result.info}`));
        }
      });
    });
  };