import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../api/axiosInstance';
import './PasswordManagement.css';

const PasswordManagement = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await axiosInstance.post('/users/change-password/', {
        old_password: values.oldPassword,
        new_password: values.newPassword
      });

      message.success(t('Password updated successfully'));
      form.resetFields();
    } catch (error) {
      if (error.response?.status === 400) {
        message.error(t('Current password is incorrect'));
      } else {
        message.error(t('Failed to update password'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className="password-management-form"
    >
      <Form.Item
        name="oldPassword"
        label={t('Current Password')}
        rules={[
          { required: true, message: t('Please enter your current password') }
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="newPassword"
        label={t('New Password')}
        rules={[
          { required: true, message: t('Please enter your new password') },
          { min: 8, message: t('Password must be at least 8 characters') },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/,
            message: t('Password must contain uppercase, lowercase and numbers')
          }
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label={t('Confirm New Password')}
        dependencies={['newPassword']}
        rules={[
          { required: true, message: t('Please confirm your new password') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('Passwords do not match')));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('Change Password')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordManagement;