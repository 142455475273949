import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import defaultProfilePic from '../../assets/images/default_profile_pic.png';
import './MNavBar.css';

const BottomNavbar = ({ setIsAuthModalOpen }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(state => state.auth);

  // get profile picture
  const getProfilePicture = () => {
    if (user && user.profile_picture) {
      return user.profile_picture;
    }
    return defaultProfilePic;
  };

  useEffect(() => {
    const navbar = document.querySelector('.bottom-navbar');
    console.log('Navbar position:', navbar.getBoundingClientRect());
  }, [isAuthenticated]); // Add isAuthenticated as a dependency if not already present

  return (
    <nav className="bottom-navbar">
      <Link to="/" className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
        <i className="fas fa-home"></i>
        <span>{t('Home')}</span>
      </Link>
      <Link to="/discover" className={`nav-item ${location.pathname === '/discover' ? 'active' : ''}`}>
        <i className="fas fa-compass"></i>
        <span>{t('Discover')}</span>
      </Link>
      <Link to="/tours" className={`nav-item ${location.pathname === '/tours' ? 'active' : ''}`}>
        <i className="fas fa-bus"></i>
        <span>{t('Tours')}</span>
      </Link>
      <Link to="/chat" className={`nav-item ${location.pathname === '/chat' ? 'active' : ''}`}>
        <i className="fas fa-comments"></i>
        <span>{t('GoAssistant')}</span>
      </Link>
      {isAuthenticated ? (
        <Link to="/profile" className={`nav-item profile-container ${location.pathname === '/profile' ? 'active' : ''}`}>
          <img 
            src={getProfilePicture()} 
            alt={t('Profile')} 
            className="profile-picture" 
          />
          <span>{t('Profile')}</span>
          {/* {isDropdownOpen && <ProfileDropdown onNavigate={handleNavigation} position="top"/>} */}
        </Link>
      ) : (
        <div className="nav-item" onClick={() => dispatch(setIsAuthModalOpen(true))}>
          <i className="fas fa-user"></i>
          <span>{t('Log In')}</span>
        </div>
      )}
    </nav>
  );
}

export default BottomNavbar;