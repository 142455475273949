import React, { useState } from 'react';
import { Input, List, Avatar, Spin, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import axiosInstance from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function DiscoverSearch({ onSearchResults }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const searchContent = async (query) => {
    if (!query.trim()) {
      setUsers([]);
      onSearchResults({ posts: [], users: [] });
      return;
    }

    setLoading(true);
    try {
      const [usersResponse, postsResponse] = await Promise.all([
        axiosInstance.get(`/users/profile/search/?q=${query}`),
        axiosInstance.get(`/posts/search/?q=${query}`)
      ]);

      const usersWithStatus = usersResponse.data.map(user => ({
        ...user,
        followRequestStatus: user.follow_request_status
      }));

      setUsers(usersWithStatus);
      onSearchResults({
        users: usersWithStatus,
        posts: postsResponse.data
      });

    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(searchContent, 300);

  const handleSearch = (e) => {
    const value = e.target.value;
    console.log(value);
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`, {
      state: { previousSearch: searchQuery }
    });
  };

  const handleFollowToggle = async (e, userId, isFollowing) => {
    e.stopPropagation();
    try {
      const user = users.find(u => u.id === userId);
      const isPrivate = user?.is_private;
      
      if (isPrivate) {
        const response = await axiosInstance.post(`/users/profile/${userId}/follow_request/`);
        if (response.status === 200) {
          setUsers(prevUsers => prevUsers.map(user => 
            user.id === userId 
              ? { ...user, followRequestStatus: 'pending' }
              : user
          ));
        }
      } else {
        const endpoint = `/users/profile/${userId}/${isFollowing ? 'unfollow' : 'follow'}/`;
        const response = await axiosInstance.post(endpoint);
        
        if (response.status === 200) {
          setUsers(prevUsers => prevUsers.map(user => 
            user.id === userId 
              ? { ...user, is_following: !isFollowing }
              : user
          ));
        }
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
    }
  };

  const getFollowButtonText = (user) => {
    if (user.followRequestStatus === 'pending') {
      return 'Requested';
    }
    return user.is_following ? 'Unfollow' : 'Follow';
  };

  return (
    <div className="user-search">
      <div className="discover-search-container">
        <Input
          placeholder="Search posts and people..."
          value={searchQuery}
          onChange={handleSearch}
          className="discover-search-input"
        />
        <button className="discover-search-btn">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>

      {users.length > 0 && (
        <div className="discover-results">
          {loading ? (
            <div className="loading-container">
              <Spin />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={users}
              renderItem={user => (
                <List.Item 
                  className="user-list-item"
                  onClick={() => handleUserClick(user.id)}
                  actions={[
                    <Button
                      onClick={(e) => handleFollowToggle(e, user.id, user.is_following)}
                      type={user.is_following ? "default" : "primary"}
                    >
                      {getFollowButtonText(user)}
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar 
                        src={user.profile_picture} 
                        icon={!user.profile_picture && <UserOutlined />}
                      />
                    }
                    title={user.display_name}
                    description={`@${user.username}`}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DiscoverSearch; 